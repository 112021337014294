import { Component, OnInit, Input, EventEmitter, Output, forwardRef } from '@angular/core';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClientEntity } from 'src/app/shared/entities/client.entity';
import { ClientService } from 'src/app/shared/services/client.service';
import {Formatter} from '../../../utils/formatter';

@Component({
  selector: 'app-async-select-client',
  templateUrl: './async-select-client.component.html',
  styleUrls: ['./async-select-client.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AsyncSelectClientComponent),
    multi: true,
  }, ClientService]
})


export class AsyncSelectClientComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() filterIds: number[] = [];
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() clientsLoaded = new EventEmitter<Array<ClientEntity>>();

  loading: boolean;
  clients: Array<ClientEntity> = [];
  input: string;
  constructor(
    private clientService: ClientService,
  ) {
  }

  private loadClients(page = 1): void {
    this.loading = true;
    this.clientService.setParam('page', page);
    if (this.clientService.getUserLogged()?.provider?.id) {
      this.clientService.setParam('provider', this.clientService.getUserLogged().provider.id);
    }
    this.clientService.findAll().then(
      (data: any) => {
        this.clients = [...this.clients, ...data.results];
        this.clients.map(client => {
          client.label = `${client.cpf} - ${client.firstName} ${client.lastName}`;
        });

        if (this.clients.length < data.count) {
          this.loadClients(page + 1);
          return;
        }

        if (this.filterIds?.length) {
          this.clients = this.clients.filter(p => this.filterIds.includes(p.id));
        }

        this.clientsLoaded.emit(this.clients);
        this.loading = false;
      }, () => {
        this.clientsLoaded.emit(this.clients);
        this.loading = false;
      },
    );
  }
  ngOnInit(): void {
    this.loadClients();
  }
  searchFn(term: string, client: ClientEntity): boolean {
    return (client.cpf?.includes(term) || client.fullName?.toLowerCase().includes(term.toLowerCase()));
  }
  getCpf(cpf: string): string {
    return Formatter.cpf(cpf);
  }
  onChange: any = () => { };
  onTouch: any = () => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }

}
