<div class="reason">
  <div class="header-modal">
    <div class="close" (click)="close()">&times;</div>
  </div>
  <div class="body-modal">
    <form [formGroup]="form" id="form-reason-id" (submit)="confirm()">
      <div class="form-row">
        <div class="col-md-12">
          <label for="reason">Qual o motivo?<small><b>(mínimo 10 caractéres)</b></small></label>
          <textarea formControlName="reason" id="reason" class="form-control" rows="4" style="height: auto; resize: none"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="footer-modal mt-1">
    <div class="row">
      <div class="col-md-3 offset-md-6">
        <button mat-raised-button type="button" class="btn-block" (click)="close()">CANCELAR</button>
      </div>
      <div class="col-md-3">
        <button mat-raised-button type="submit" form="form-reason-id" class="btn-block" color="primary" [disabled]="form.get('reason').value?.length < 10">CONFIRMAR</button>
      </div>
    </div>
  </div>
</div>
