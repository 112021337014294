import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { ProviderSubscriptionEntity } from '../entities/provider-subscription.entity';

@Injectable({
  providedIn: 'root'
})
export class ProvidersSubscriptionService extends ApiService<ProviderSubscriptionEntity> {

  constructor(http: HttpClient) {
    super('providers-subscriptions', http);
  }
}
