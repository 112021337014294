import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';


@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss'],
})
export class ReasonModalComponent implements OnInit {
  form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReasonModalComponent>,
    private formBuilder: FormBuilder,
  ) {
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      reason: new FormControl(null),
    });
  }

  close(): void {
    this.dialogRef.close({ confirmed: false, reason: null});
  }
  confirm(): void {
    const { reason } = this.form.getRawValue();
    this.dialogRef.close({ confirmed: true, reason });
  }
}
