import {PaginatedListEntity} from './paginated-list.entity';
import {LogsEntity} from '../logs.entity';
import * as moment from 'moment';

export class LogsPaginatedListEntity extends PaginatedListEntity<LogsEntity> {
  constructor(data) {
    super(data);
  }
  getMatTableDataSource(): Array<object> {
    return this.results.map((m) => ({
      ...m,
      event: m.event === 'deactive' ? 'Inativado' : 'Ativado',
      createDate: moment(m.createDate).format('DD/MM/YYYY [às] HH:mm')
    }));
  }
}
