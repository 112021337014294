import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {ProcedureEntity} from '../entities/procedure.entity';

@Injectable()
// @ts-ignore
export class ProcedureService extends ApiService<ProcedureEntity> {
  constructor(http: HttpClient) {
    super('procedures', http);
  }
}
