import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {ConfirmYesNoModalComponent} from './confirm-yes-no-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  declarations: [
    ConfirmYesNoModalComponent,
  ],
  exports: [
    ConfirmYesNoModalComponent,
  ],
})
export class ConfirmYesNoModalModule {}
