import { BaseEntity } from './base.entity';
import { BaseInterface } from '../interfaces/base.interface';
import {ChoiceEntity} from './choice.entity';

export class QuestionEntity extends BaseEntity implements BaseInterface{
  name: string;
  fieldPlaceholder: string;
  choices: Array<ChoiceEntity>;
  fieldType: 'input' | 'choice';
  constructor(id?: number) {
    super(id);
  }
  setRawValue(entity): void {
    const {
      name,
      fieldType,
      choices,
      fieldPlaceholder,
    } = entity;
    this.fieldType = fieldType;
    this.name = name;
    this.choices = choices;
    this.fieldPlaceholder = fieldPlaceholder;
  }
}
