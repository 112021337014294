import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NotificationModalComponent} from './notification-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [
    NotificationModalComponent,
  ],
  exports: [
    NotificationModalComponent,
  ],
})

export default class NotificationModalModule {
}
