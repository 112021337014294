import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {QuestionEntity} from '../entities/question.entity';

@Injectable()
// @ts-ignore
export class QuestionService extends ApiService<QuestionEntity> {
  constructor(http: HttpClient) {
    super('questions', http);
  }
}
