import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

const REASONS = {
  SUCCESS: {
    icon: 'check_circle',
  },
  ERROR: {
    icon: 'highlight_off',
  },
};
@Component({
  selector: 'app-notification-modal-component',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent {
  type: string;
  text: string;
  reason = null;
  constructor(
    private dialogRef: MatDialogRef<NotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.type = this.data.type;
    this.text = this.data.text;
    this.reason = REASONS[this.type.toUpperCase()];
  }
  close(): void {
    this.dialogRef.close();
  }
}
