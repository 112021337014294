import {PaginatedListEntity} from './paginated-list.entity';
import {PerformanceRatingEntity} from '../performance-rating.entity';

export class PerformanceRatingPaginatedListEntity extends PaginatedListEntity<PerformanceRatingEntity> {
  constructor(data) {
    super(data);
  }
  getMatTableDataSource(): Array<object> {
    return this.results.map(m => ({
      ...m,
      client: m.client?.email,
      description: m.description ? m.description : 'sem descrição',
    }));
  }
}
