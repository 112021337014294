import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProcedureService } from 'src/app/shared/services/procedure.service';
import { ProcedureEntity } from 'src/app/shared/entities/procedure.entity';
import { ProviderSubscriptionEntity } from '../../../entities/provider-subscription.entity';

@Component({
  selector: 'app-async-select-procedure',
  templateUrl: './async-select-procedure.component.html',
  styleUrls: ['./async-select-procedure.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectProcedureComponent),
      multi: true,
    },
    ProcedureService
  ],
})
export class AsyncSelectProcedureComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() filterIds: number[] = [];
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() proceduresLoaded = new EventEmitter<Array<ProcedureEntity>>();
  @Output() selectedProcedure = new EventEmitter<ProcedureEntity>();

  loading: boolean;
  procedures: Array<ProcedureEntity> = [];
  input: string;

  constructor(
    private procedureService: ProcedureService,
  ) {
  }

  private loadProcedures(page = 1): void {
    this.loading = true;
    this.procedureService.setParam('page', page);
    // this.procedureService.setParam('provider', this.procedureService.getUserLogged()?.provider?.id);
    this.procedureService.findAll().then(
      (data: any) => {
        console.log(data);
        this.procedures = [...this.procedures, ...data.results];
        if (this.procedures.length < data.count) {
          this.loadProcedures(page + 1);
          return;
        }

        // this.procedures = this.procedures.filter((procedure) => procedure);
        if (this.filterIds?.length) {
          this.procedures = this.procedures.filter(p => this.filterIds.includes(p.id));
        }

        this.proceduresLoaded.emit(this.procedures);
        this.loading = false;
      }, () => {
        this.proceduresLoaded.emit(this.procedures);
        this.loading = false;
      },
    );
  }

  ngOnInit(): void {
    this.loadProcedures();
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: string): void {
    this.input = input;
  }

  selected(item: ProcedureEntity) {
    this.selectedProcedure.emit(item);
  }
}
