<div class="notification-dialog">
  <div class="row">
    <div class="col-md-12">
      <div class="close"><span (click)="close()">&times;</span></div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-12 text-center"
      [ngClass]="type"
    >
      <mat-icon [ngClass]="type">{{ reason.icon }}</mat-icon>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-12 text-center">
      <p>{{ text }}</p>
    </div>
  </div>
  <br>
  <br>
  <div class="row">
    <div class="col-md-12 text-center">
      <button type="button" mat-flat-button [ngClass]="type" (click)="close()">
        OK
      </button>
    </div>
  </div>
</div>
