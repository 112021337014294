import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ProviderService} from '../../../services';
import {MatColumnEntity} from '../../../entities/mat-column.entity';
import {ProviderEntity} from '../../../entities/provider.entity';
import {PerformanceRatingPaginatedListEntity} from '../../../entities/paginated/performance-rating-paginated-list.entity';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';


@Component({
  selector: 'app-show-rating-comment-modal',
  templateUrl: './show-rating-comment-modal.component.html',
  styleUrls: ['./show-rating-comment-modal.component.scss'],
  providers: [ProviderService],
})
export class ShowRatingCommentModalComponent implements OnInit {
  provider: ProviderEntity;
  loading: boolean;
  dataSource: any;
  displayedColumns: string[];
  columnNames: MatColumnEntity[];
  performanceRatingPaginatedList: PerformanceRatingPaginatedListEntity;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    private dialogRef: MatDialogRef<ShowRatingCommentModalComponent>,
  ) {
    this.provider = data.provider;
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private tableConfig(): void {
    this.columnNames = [
      new MatColumnEntity('client', 'Usuário'),
      new MatColumnEntity('description', 'Descrição'),
    ];
    this.displayedColumns = this.columnNames.map((x) => x.id);
    this.displayedColumns.push('rating');
  }
  ngOnInit(): void {
    this.tableConfig();
    this.search({ rating: null });
  }

  close(): void {
    this.dialogRef.close(false);
  }
  search(n: any): void {
    this.loading = true;
    const value = n.rating;
    if (value) {
      this.providerService.setParam('rating', value);
    }
    this.providerService.setParam('provider', this.provider.id);
    this.providerService.performanceRating().then(
      (data) => {
        this.loading = false;
        this.performanceRatingPaginatedList = new PerformanceRatingPaginatedListEntity(data);
        this.dataSource = new MatTableDataSource(this.performanceRatingPaginatedList.getMatTableDataSource());
        setTimeout(() => this.dataSource.paginator = this.paginator);
      }, () => {
        this.loading = false;
      }
    );
  }
  changePage(page): void {
    this.loading = true;
    const {
      previousPageIndex,
      pageIndex,
    } = page;
    const previousData = this.performanceRatingPaginatedList.getResultsAlreadyLoadedByPage(pageIndex);
    if (!previousData) {
      this.providerService.setParam('page', pageIndex + 1);
      this.providerService.performanceRating().then(
        (data: any) => {
          this.performanceRatingPaginatedList.update(previousPageIndex, pageIndex, data);
          this.dataSource.data = this.performanceRatingPaginatedList.getMatTableDataSource();
          this.loading = false;
        }, () => this.loading = false,
      );
    } else {
      this.performanceRatingPaginatedList.setResults(previousData);
      this.dataSource.data = this.performanceRatingPaginatedList.getMatTableDataSource();
      this.loading = false;
    }
  }
}
