<div class="yes-no-modal">
  <div class="row">
    <div class="col-md-12 text-center">
      <h4 class="title">Confirmação</h4>
      <div class="close"><span (click)="close()">&times;</span></div>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12 text-center">
      <label>{{ question }}</label>
    </div>
  </div>
  <hr>
  <div class="confirm-schedule-modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button mat-flat-button type="button" (click)="close()" class="btn-cancel btn-block">Não</button>
      </div>
      <div class="col-md-6">
        <button mat-raised-button type="button" (click)="confirm()" color="primary" class="btn-block">Sim</button>
      </div>
    </div>
  </div>
</div>
