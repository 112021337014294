import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { BlogsEntity } from '../entities/blogs.entity';
import { CategoryEntity } from '../entities/category.entity';
import { catchError, map } from 'rxjs/operators';
import { CheckOutEntity } from '../entities/checkout.entity';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BlogsService extends ApiService<BlogsEntity> {

  constructor(http: HttpClient) {
    super('blogs', http);
  }

  /**
   * This category is from WordPress
   */
  allCategories(): Promise<CategoryEntity[]> {
    return this.http
      .get(environment.url_category)
      .pipe(
        map(response => {
          return response as CheckOutEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

}
