import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forEach } from 'lodash';
import { ApiService } from './api.service';
import {catchError, map} from 'rxjs/operators';
import {UserEntity} from '../entities';
import {ILogin} from '../interfaces';
import {IProfile} from '../interfaces/profile.interface';
import {AdvancedUserSearchEntity} from '../entities/advanced-user-search.entity';
import {Formatter} from '../utils/formatter';

@Injectable()
// @ts-ignore
export class UserService extends ApiService<UserEntity> {
  constructor(http: HttpClient) {
    super('users', http);
  }
  login(login: ILogin): Promise<IProfile> {
    return this.http.post(`${this.api}/auth/user/login/`, JSON.stringify(login), { headers: this.header })
      .pipe(
        map(response => response as IProfile),
        catchError(this.handleError)
      ).toPromise();
  }
  passwordReset(email: any): Promise<any> {
    return this.http.post(`${this.api}/auth/user/password_reset/`, JSON.stringify(email), { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
}
