import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {SubscriptionEntity} from '../entities/subscription.entity';

@Injectable()
// @ts-ignore
export class SubscriptionService extends ApiService<SubscriptionEntity> {
  constructor(http: HttpClient) {
    super('subscriptions', http);
  }
}
