<div class="items-checkout">
  <div class="header-modal">
    <div class="close" (click)="close()">&times;</div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col-md-12 text-center m-auto">
      <mat-progress-spinner diameter="50" style="margin: auto" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>
  <div class="body-modal" *ngIf="!loading">
    <div class="container-table">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="{{ column.id }}" *ngFor="let column of columnNames">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ column.value }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="column.id !== 'scheduleDatetime'">{{element[column.id]}}</span>
            <span *ngIf="column.id == 'scheduleDatetime'">
              <div>
                <label>Selecione a Data</label>
                <mat-form-field>
                  <input matInput [ngxMatDatetimePicker]="picker" [(ngModel)]="myDatePicker"
                    (ngModelChange)="updateDatetime(element.id, myDatePicker)">

                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                  <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                </mat-form-field>
              </div>
            </span>
          </mat-cell>
        </ng-container>



        <!-- <mat-cell *matCellDef="scheduleDatetime">
            a
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date & time"
                [(ngModel)]="myDatePicker">

              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

              <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
            </mat-form-field>
          </mat-cell> -->

        <!-- <form [formGroup]="dateForm">
              <label for="schyeduleDate">De:</label>
              <mat-datepicker #startDate></mat-datepicker>
              <input id="startDate" class="form-control" [matDatepicker]="startDate" (click)="startDate.open()"
                formControlName="startDate" />
            </form>
            {{element.scheduleDatetime}} -->

        <!-- <ng-container matColumnDef="scheduleDatetime">
          <th mat-header-cell *matHeaderCellDef>Data</th>

        </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSize]="7" [length]="dataSource?.data?.length" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

</div>