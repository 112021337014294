import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PmeService } from 'src/app/shared/services/pme.service';
import { PmeEntity } from 'src/app/shared/entities/pme.entity';

@Component({
  selector: 'app-async-select-pme',
  templateUrl: './async-select-pme.component.html',
  styleUrls: ['./async-select-pme.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectPmeComponent),
      multi: true,
    },
    PmeService,
  ],
})
export class AsyncSelectPmeComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  loading: boolean;
  pmes: Array<PmeEntity> = [];
  input: string;
  @Output() pmeSelected = new EventEmitter<PmeEntity>();
  @Output() clear = new EventEmitter<boolean>();

  constructor(
    private pmeService: PmeService,
  ) {
  }
  private loadPmes(page = 1): void {
    this.loading = true;
    this.pmeService.setParam('page', page);
    this.pmeService.findAll().then(
      (data: any) => {
        this.pmes = [...this.pmes, ...data.results];
        if (this.pmes.length < data.count) {
          this.loadPmes(page + 1);
        }
        this.loading = false;
      }, () => this.loading = false,
    );
  }
  private loadPme(id): void {
    if (!id) {
      return;
    }
    this.pmeService.findById(id).then(
      (data: any) => {
        this.pmes = [ data ];
      },
    );
  }
  ngOnInit(): void {
    if (this.pmeService.getUserLogged()?.isAdmin()) {
      this.loadPmes();
    } else {
      this.loadPme(this.pmeService.getUserLogged()?.provider?.id);
    }
  }
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }

  selected(item: PmeEntity) {
    this.pmeSelected.emit(item);
  }

  clearInput($event: any) {
    this.clear.emit(true);
  }
}
