import {TableEntity} from './table.entity';
import { forEach, get } from 'lodash';
import * as moment from 'moment';
import {HeaderEntity} from './header.entity';

export class LineEntity {
  public text: string;
  public style: string;
  public table: TableEntity;
  public layout: any;
  constructor(text: string = null, className = null) {
    this.text = text;
    this.style = className;
    this.layout = null;
  }
  buildTable(header: HeaderEntity[], data: any[]) {
    const testField = (item) => {
      if (typeof item === 'number') {
        return item;
      }
      if (!moment(moment(item).toDate()).isValid()) {
        return item;
      }
      const format = item ? item.length === 10 ? 'DD/MM/YYYY' : 'DD/MM/YYYY [às] HH:mm' : null;
      return moment(item).format(format);
    };
    this.style = 'table';
    this.layout = 'lightHorizontalLines';
    this.table = new TableEntity();
    this.table.insertRow(header.map(m => m.text), true);
    forEach(data, it => {
      this.table.insertRow(header.map(m => testField(get(it, m.key, ''))));
    });
  }
}
