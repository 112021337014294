import { Component, OnInit, Inject } from '@angular/core';
import { ProcedureEntity } from 'src/app/shared/entities/procedure.entity';
import * as moment from 'moment';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ProviderScheduleEntity } from 'src/app/shared/entities/provider-schedule.entity';
import { ProvidersScheduleService } from 'src/app/shared/services/providers-schedule.service';
import { ClientEntity } from 'src/app/shared/entities/client.entity';
import { Toaster } from 'ngx-toast-notifications';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DAYS_OF_WEEK } from 'calendar-utils';
import {debounceTime, tap} from 'rxjs/operators';
import {KitEntity} from '../../../entities/kit.entity';
import {CheckOutEntity} from '../../../entities/checkout.entity';

const clientImgDedault = '../../../../assets/images/default-user.png';

@Component({
  selector: 'app-create-schedule-event-modal',
  templateUrl: './create-schedule-event-modal.component.html',
  styleUrls: ['./create-schedule-event-modal.component.scss'],
  providers: [ProvidersScheduleService],
})
export class CreateScheduleEventModalComponent implements OnInit {
  loading: boolean;
  kits: Array<any> = [];
  procedures: Array<ProcedureEntity> = [];
  formEvent: FormGroup;
  checkout: CheckOutEntity;
  clientTypes = [];
  clients: Array<ClientEntity>;
  clientImg = '../../../../assets/images/default-user.png';
  clientSelected: ClientEntity;
  clientTypeSelected: string;
  providerSchedule: ProviderScheduleEntity;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateScheduleEventModalComponent>,
    private toaster: Toaster,
    private fb: FormBuilder,
    private providerScheduleService: ProvidersScheduleService
  ) {}

  ngOnInit(): void {
    this.clientTypes = [{
      id: 'app',
      label: 'Cliente App',
    }, {
      id: 'local',
      label: 'Cliente Externo (Particular)',
    }];
    this.clientTypeSelected = 'app';

    this.buildForm();
  }

  private buildForm(): void {
    this.formEvent = this.fb.group({
      date: new FormControl(null, Validators.required),
      startHour: new FormControl(null, Validators.required),
      endHour: new FormControl(null),
      client: new FormControl(null, Validators.required),
      procedures: new FormControl(null),
      kit: new FormControl(null),
      phone: new FormControl(null, Validators.required),
      type: new FormControl(this.clientTypeSelected, Validators.required),
      particularProcedure: new FormControl(null, Validators.required),
      particularClientName: new FormControl(null, Validators.required),
      healthPlan: new FormControl(null),
    });
    this.valueChanges();


    this.onChangeClientType();
  }
  private valueChanges(): void {
    this.formEvent.get('type').valueChanges.pipe(debounceTime(100), tap(() => {
    })).subscribe(() => {
      this.formEvent.get('kit').setValue(null);
    });
    this.formEvent.get('kit').valueChanges.pipe(debounceTime(100), tap((val) => {
      this.procedures = [];
    })).subscribe(() => {
      const kitId = this.formEvent.get('kit').value;
      if (kitId) {
        const kitItemSelected = this.kits.find((k) => k.id.toString() === kitId.toString());
        this.checkout = new CheckOutEntity(kitItemSelected.checkout.id);
        const kit = kitItemSelected.kit;
        if (!!kit) {
          this.procedures = kit.procedures.map(m => m.procedure);
          // this.procedures = [...this.kits.filter(f => f.kit.id.toString() === kit.id.toString()).map((it) => it.procedure)];
        }
      }
    });
  }
  onClientsLoaded = ($event: Array<ClientEntity>) => {
    this.clients = $event;
    this.onChangeClient();
  }

  onChangeClient(): void {
    const idCliente = this.formEvent.get('client')?.value;

    if (!idCliente) {
      this.clientImg = clientImgDedault;
      this.clientSelected = null;
    } else if (idCliente !== this.clientSelected?.id) {
      if (this.clients) {
        this.clientSelected = this.clients.filter(
          (client) => client.id === idCliente
        )[0];
        this.formEvent.controls['phone'].setValue(this.clientSelected.phone);
        this.clientImg = this.clientSelected.image ?? clientImgDedault;
      }
    }
  }

  onChangeClientType(): void {
    this.clientImg = clientImgDedault;
    this.clientSelected = null;
    this.clientTypeSelected = this.formEvent.get('type').value;
    this.formEvent.get('phone').setValue('');

    if (this.clientTypeSelected === 'app') {
      this.formEvent.removeControl('particularClientName');
      this.formEvent.removeControl('healthPlan');
      this.formEvent.removeControl('particularProcedure');
      this.formEvent.addControl(
        'client',
        new FormControl(null, Validators.required)
      );
      this.formEvent.addControl(
        'kit',
        new FormControl(null, Validators.required)
      );
      this.formEvent.addControl(
        'procedures',
        new FormControl(null, Validators.required)
      );
    }
    if (this.clientTypeSelected === 'local') {
      this.formEvent.removeControl('client');
      this.formEvent.removeControl('procedures');
      this.formEvent.removeControl('kit');
      this.formEvent.addControl(
        'particularClientName',
        new FormControl(null, Validators.required)
      );
      this.formEvent.addControl('healthPlan', new FormControl(null));
      this.formEvent.addControl(
        'particularProcedure',
        new FormControl(null, Validators.required)
      );
    }
    this.valueChanges();
  }

  close(): void {
    this.dialogRef.close(false);
  }
  onSubmit = () => {
    if (!this.isValidSchedule()) return;

    this.loading = true;

    this.providerSchedule = new ProviderScheduleEntity();

    if (this.clientSelected) {
      this.providerSchedule.client = this.clientSelected;
      this.providerSchedule.procedures = this.procedures.filter((p) =>
        this.formEvent.get('procedures').value.includes(p.id)
      );
    } else {
      this.providerSchedule.client = null;
      this.providerSchedule.procedures = [];
    }

    this.providerSchedule.provider = this.data.provider;
    this.providerSchedule.type = this.formEvent.get('type').value;
    this.providerSchedule.phone = this.formEvent.get('phone').value;
    this.providerSchedule.particularClientName = this.formEvent.get(
      'particularClientName'
    )?.value;
    this.providerSchedule.particularProcedure = this.formEvent.get(
      'particularProcedure'
    )?.value;
    this.providerSchedule.startDate = this.getFormatedStartDate();
    if (this.clientTypeSelected !== 'app') {
      this.providerSchedule.endDate = this.getFormatedEndDate();
    } else {
      this.providerSchedule.endDate = null;
    }
    this.providerSchedule.healthPlan = this.getHealthPlan();
    this.providerSchedule.isActive = true;
    this.providerSchedule.isAccepted = true;
    this.providerSchedule.isUserApp = false;
    this.providerSchedule.isConfirmed = false;
    this.providerSchedule.isPerformed = false;
    this.providerSchedule.checkout = this.checkout;

    this.providerScheduleService.save(this.providerSchedule).then(
      () => {
        this.showSuccess('Agendamento salvo com sucesso !');
        this.dialogRef.close(true);
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  isOpeningHour() {

    if (!moment(this.getFormatedStartDate()).isValid()) {
      return true;
    }
    if (!moment(this.getFormatedEndDate()).isValid()) {
      return true;
    }

    const scheduleWeekDay =
      DAYS_OF_WEEK[moment(this.getFormatedStartDate()).weekday()];
    const openingHour = this.data.provider.openingHour.filter(
      (day) => day.weekDay.toUpperCase() === scheduleWeekDay
    )[0];

    if (!openingHour) {
      return false;
    }

    const scheduleStartDate = this.getFormatedStartDate();
    const scheduleEndDate = this.getFormatedEndDate();

    const weekDayOpenHour = moment(scheduleStartDate).format(
      `YYYY-MM-DD[T]${openingHour.openHour}`
    );
    const weekDayCloseHour = moment(scheduleStartDate).format(
      `YYYY-MM-DD[T]${openingHour.closeHour}`
    );
    return (moment(scheduleStartDate).isSameOrAfter(weekDayOpenHour) && moment(scheduleEndDate).isSameOrBefore(weekDayCloseHour));
  }

  isValidSchedule = () => {
    let isValidDates = true;

    if (moment(this.getFormatedStartDate()).isBefore(moment())) {
      this.showInfo('Não é possivel criar agendamentos para dias anteriores.');
      isValidDates = false;
    }
    return isValidDates;
  }
  isInvalidForm(): boolean {
    if (this.formEvent?.invalid) {
      return true;
    }
    if (this.clientTypeSelected === 'app') {
      if (!this.formEvent.get('kit').value) {
        return true;
      }
      if (!this.formEvent.get('procedures').value?.length) {
        return true;
      }
    } else if (this.clientTypeSelected === 'local') {
      if (!this.formEvent.get('endHour').value) {
        return true;
      }
    }
  }
  getFormatedStartDate = () =>
    moment(
      `${this.formEvent.get('date').value} ${
        this.formEvent.get('startHour').value
      }`
    ).format('YYYY-MM-DD[T]HH:mm:ss');

  getFormatedEndDate = () =>
    moment(
      `${this.formEvent.get('date').value} ${
        this.formEvent.get('endHour').value
      }`
    ).format('YYYY-MM-DD[T]HH:mm:ss');

  getHealthPlan = () => {
    if (!this.formEvent.get('healthPlan')?.value) return null;

    const isWhitespace =
      (this.formEvent.get('healthPlan')?.value || '').trim().length === 0;
    if (isWhitespace) return null;

    return this.formEvent.get('healthPlan')?.value;
  };

  deleteProviderSchedule = () => {
    this.loading = true;
    this.providerScheduleService
      .save({ ...this.providerSchedule, isActive: false })
      .then(() => {
        this.loading = false;
        this.showSuccess('Consulta removida com sucesso !');
        this.dialogRef.close(true);
      });
  };

  showSuccess(text): void {
    this.toaster.open({
      text,
      caption: 'OK!',
      type: 'success',
      position: 'top-right',
    });
  }

  showInfo(text): void {
    this.toaster.open({
      text,
      caption: '',
      type: 'info',
      position: 'top-right',
    });
  }
}
