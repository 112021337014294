import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './shared/guards';


const routes: Routes = [
  { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'recover-password', loadChildren: () => import('./recover-password/recover-password.module').then(m => m.RecoverPasswordModule) },
  { path: 'user/new-password/:token', loadChildren: () => import('./new-password/new-password.module').then(m => m.NewPasswordModule) },
  { path: 'client/new-password/:token', loadChildren: () => import('./new-password/new-password.module').then(m => m.NewPasswordModule) },
  { path: 'perfil-saude', loadChildren: () => import('./layout/pages/pmes/perfil-saude/perfil-saude.module').then(m => m.PerfilSaudeModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
