import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Formatter } from '../../../utils/formatter';
import { SubscriptionEntity } from '../../../entities/subscription.entity';
import { SubscriptionService } from '../../../services/subscription.service';

@Component({
    selector: 'app-async-select-subscription',
    templateUrl: './async-select-subscription.component.html',
    styleUrls: ['./async-select-subscription.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AsyncSelectSubscriptionComponent),
            multi: true,
        },
        SubscriptionService
    ],
})
export class AsyncSelectSubscriptionComponent implements OnInit {

    @Input() multiple = false;
    @Input() appendToBody = false;
    @Input() formGroup: FormGroup;
    @Input() formControlName: string;
    @Input() bindValue = 'id';
    @Input() isPme = false;
    @Input() id?: string;
    @Input() showFullName = false;
    @Output() SubscriptionsLoaded = new EventEmitter<Array<SubscriptionEntity>>();
    @Output() subscriptionSelected = new EventEmitter<SubscriptionEntity>();
    @Output() clear = new EventEmitter<boolean>();

    loading: boolean;
    providers: Array<SubscriptionEntity> = [];
    input: string;

    constructor(
        private subscriptionService: SubscriptionService,
    ) {
    }

    private loadSubscriptions(page = 1): void {
        this.loading = true;
        this.subscriptionService.setParam('page', page);
        this.subscriptionService.findAll().then(
            (data: any) => {
                data.results.map((i) => {
                    i.fullName = i.id + ' - ' + i.name;
                    return i;
                });
                this.providers = [...this.providers, ...data.results];
                if (this.providers.length < data.count) {
                    this.loadSubscriptions(page + 1);
                    return;
                }
                if (this.isPme) {
                    this.providers = this.providers.filter((pme) => pme.subscriptionPme === true);
                }
                this.SubscriptionsLoaded.emit(this.providers);
                this.loading = false;
            }, () => {
                this.SubscriptionsLoaded.emit(this.providers);
                this.loading = false;
            },
        );
    }

    ngOnInit(): void {
        this.loadSubscriptions();
    }

    onChange: any = () => {
    };
    onTouch: any = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(input: string): void {
        this.input = input;
    }

    searchFn(term: string, subscription: SubscriptionEntity): boolean {
        return (subscription.name.includes(term) || subscription.nameToUser?.toLowerCase().includes(term.toLowerCase()));
    }

    getCnpj(cnpj: string): string {
        return Formatter.cnpj(cnpj);
    }

    selected(item: SubscriptionEntity) {
        this.subscriptionSelected.emit(item);
    }

    clearInput($event: any) {
        this.clear.emit(true);
    }

}
