import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {StatusEntity} from '../entities/status.entity';

@Injectable()
// @ts-ignore
export class StatusService extends ApiService<StatusEntity> {
  constructor(http: HttpClient) {
    super('status', http);
  }
}
