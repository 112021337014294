<div class="notification-dialog">
  <div class="row">
    <div
      class="col-md-12 text-center"
      [ngClass]="'success'"
    >
      <mat-icon [ngClass]="'success'">schedule</mat-icon>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <p>Informe Data/Hora do Agendamento ( Mín. 3+ horas )</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="form" (submit)="confirm()">
        <div class="form-row">
          <div class="col-6">
            <label for="date">Data:</label>
            <mat-datepicker #date></mat-datepicker>
            <input
              id="date"
              class="form-control"
              [min]="today"
              [matDatepicker]="date"
              (click)="date.open()"
              formControlName="date"
            />
          </div>
          <div class="col-6">
            <label for="time">Hora:</label>
            <input
              id="time"
              type="time"
              (focusout)="verifyTime()"
              class="form-control"
              formControlName="time"
            />
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="col-md-6 text-center">
            <button mat-raised-button type="button" class="btn-block" (click)="cancel()">
              Cancelar
            </button>
          </div>
          <div class="col-md-6 text-center">
            <button mat-raised-button type="submit" color="primary" class="btn-block" [disabled]="isValidDate() || !isValidTime">
              Agendar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
