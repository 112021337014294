import { BaseEntity } from './base.entity';
import { BaseInterface } from '../interfaces/base.interface';
import { AddressEntity } from './address.entity';
import { ProviderTypeEntity } from './provider-type.entity';
import { ProcedureValueEntity } from './procedure-value.entity';
import * as moment from 'moment';
import {Formatter} from '../utils/formatter';

export class ProviderEntity extends BaseEntity implements BaseInterface{

  tradingName: string;
  companyName: string;
  description: string;
  cnpj: string;
  whatsapp: string;
  phone1: string;
  phone2: string;
  adminName: string;
  adminEmail: string;
  adminPhone: string;
  bankAccountName: string;
  bankCpfCnpj: string;
  bankCode: string;
  bankName: string;
  bankCheckingAccount: boolean;
  useSchedule: boolean;
  bankOperationCode: string;
  getnetSubsellerId: number;
  billingProvider: ProviderEntity;
  bankAgency: string;
  bankAgencyDigit: string;
  bankAccountNumber: string;
  bankAccountNumberDigit: string;
  isActive: boolean;
  providerType: ProviderTypeEntity;
  openingHour: Array<object>;
  address: AddressEntity;
  procedures: Array<ProcedureValueEntity>;
  scheduled: boolean;
  rating: number;
  stateFiscalDocumentNumber: string;
  constructor(id?: number) {
    super(id);
  }
  setRawValue(entity): void {
    const {
      tradingName,
      companyName,
      description,
      cnpj,
      whatsapp,
      billingProvider,
      phone1,
      phone2,
      adminName,
      useSchedule,
      adminEmail,
      getnetSubsellerId,
      adminPhone,
      bankAccountName,
      bankCpfCnpj,
      bankCode,
      bankName,
      bankCheckingAccount,
      bankOperationCode,
      bankAgency,
      bankAgencyDigit,
      bankAccountNumber,
      bankAccountNumberDigit,
      isActive,
      providerType,
      openingHour,
      address,
      procedures,
      scheduled,
      rating,
      financialType,
      stateFiscalDocumentNumber,
    } = entity;
    this.tradingName = tradingName;
    this.companyName = companyName;
    this.cnpj = cnpj;
    this.isActive = isActive;
    this.useSchedule = useSchedule;
    this.getnetSubsellerId = getnetSubsellerId;
    this.description = description;
    this.whatsapp = whatsapp;
    this.phone1 = phone1;
    this.phone2 = phone2;
    this.adminName = adminName;
    this.adminEmail = adminEmail;
    this.adminPhone = adminPhone;
    this.bankCpfCnpj = bankCpfCnpj;
    this.bankName = bankName;
    this.bankCheckingAccount = bankCheckingAccount;
    this.bankAgencyDigit = bankAgencyDigit;
    this.providerType = providerType?.id ? providerType : new ProviderTypeEntity(providerType);
    this.openingHour = openingHour;
    this.address = address;
    this.procedures = procedures;
    this.scheduled = scheduled;
    this.rating = rating;
    this.stateFiscalDocumentNumber = stateFiscalDocumentNumber?.length ? stateFiscalDocumentNumber : null;
    if (financialType) {
      if (financialType === 'other-cnpj' && billingProvider) {
        this.setBillingProviderById(billingProvider);
        this.bankCode = null;
        this.bankAccountName = null;
        this.bankAgency = null;
        this.bankAccountNumber = null;
        this.bankAccountNumberDigit = null;
        this.bankOperationCode = null;
      } else if (financialType === 'this') {
        this.billingProvider = null;
        this.bankCode = bankCode;
        this.bankAccountName = bankAccountName;
        this.bankAgency = bankAgency;
        this.bankAccountNumber = bankAccountNumber;
        this.bankAccountNumberDigit = bankAccountNumberDigit;
        this.bankOperationCode = bankOperationCode;
      }
    } else {
      this.billingProvider = billingProvider;
      this.bankCode = bankCode;
      this.bankAccountName = bankAccountName;
      this.bankAgency = bankAgency;
      this.bankAccountNumber = bankAccountNumber;
      this.bankAccountNumberDigit = bankAccountNumberDigit;
      this.bankOperationCode = bankOperationCode;
    }
  }
  setAddress(address: AddressEntity): void {
    this.address = address;
  }

  setBillingProviderById(provider: number): void {
    if (Number(provider) > 0) {
      this.billingProvider = new ProviderEntity(provider);
    } else {
      this.billingProvider = null;
    }
  }

  setProcedures(procedures: Array<ProcedureValueEntity>): void {
    const calcDurationMinutes = (s: string) => {
      if (s && s !== '') {
        const date = moment(s, 'HH:mm');
        const hours = date.hours();
        const minutes = date.minutes();
        return (hours * 60) + minutes;
      } else {
        return 0;
      }
    };
    this.procedures = procedures.map((m) => ({
      ...m,
      value: parseFloat(m.value.split('.').join('').replace(',', '.')).toString(),
      durationMinutes: calcDurationMinutes(m.durationMinutes),
    })) as Array<ProcedureValueEntity>;
  }

  setOpeningHour(openingHour: Array<object>): void {
    this.openingHour = openingHour;
  }
}
