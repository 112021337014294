import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {KitEntity} from '../entities/kit.entity';
import {CouponEntity} from '../entities/coupon.entity';
import {catchError, map} from 'rxjs/operators';
import { KitPaginatedListEntity } from '../entities/paginated/kit-paginated-list.entity';

@Injectable()
// @ts-ignore
export class KitService extends ApiService<KitEntity> {
  constructor(http: HttpClient) {
    super('kits', http);
  }
  findAllCoupons(id: string): Promise<CouponEntity> {
    return this.http
      .get(`${this.url}/${id}/coupons/${this.getParamsAsString()}`)
      .pipe(
        map(response => {
          return response as CouponEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }
}
