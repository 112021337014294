import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { AttendanceGroupsEntity } from '../entities/attendance-groups.entity';

@Injectable({
  providedIn: 'root'
})
export class AttendanceGroupsService extends ApiService<AttendanceGroupsEntity> {

  constructor(http: HttpClient) {
    super('attendance-groups', http);
  }

}
