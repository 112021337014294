import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatColumnEntity } from '../../../entities/mat-column.entity';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CheckoutService } from '../../../services/checkout.service';
import { DatePipe } from '@angular/common';
import { CheckOutEntity } from 'src/app/shared/entities/checkout.entity';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-items-checkout-modal',
  templateUrl: './items-checkout-modal.component.html',
  styleUrls: ['./items-checkout-modal.component.scss'],
  providers: [CheckoutService],
})
export class ItemsCheckoutModalComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[];
  columnNames: MatColumnEntity[];
  checkoutId: number;
  loading: boolean;
  myDatePicker: Date;
  checkout: CheckOutEntity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ItemsCheckoutModalComponent>,
    private checkoutService: CheckoutService,
    public datepipe: DatePipe,
    private toaster: Toaster,
  ) {
    this.checkoutId = data.checkoutId;
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private tableConfig(): void {
    this.columnNames = [
      new MatColumnEntity('kitLabel', 'Compra'),
      new MatColumnEntity('procedureLabel', 'Procedimento'),
      new MatColumnEntity('providerLabel', 'Prestador'),
      new MatColumnEntity('scheduleDatetime', 'Data'),
    ];
    this.displayedColumns = this.columnNames.map((x) => x.id);
  }
  ngOnInit(): void {
    this.tableConfig();
    this.loading = true;
    this.checkoutService.findById(this.checkoutId.toString()).then(
      (checkout) => {
        this.checkout = checkout;
        this.myDatePicker = checkout.scheduleDatetime;
        this.dataSource = new MatTableDataSource(checkout.items.map((l) => ({
          ...l,
          kitLabel: l.kit ? l.kit.name : '',
          procedureLabel: l.procedure ? l.procedure.name : '',
          providerLabel: l.provider ? l.provider.companyName : '',
          scheduleDatetime: l.scheduleDatetime ? l.scheduleDatetime : '',
        })));
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      }, () => this.loading = false
    );
  }


  updateDatetime(itemId, time) {
    console.log(itemId);
    console.log(time);
    time = this.datepipe.transform(time, 'yyyy-MM-ddTHH:mm:ss');
    console.log(time);
    this.checkout.scheduleDatetime = time;
    this.checkoutService.save(this.checkout).then(
      () => {
        this.showSuccess('Novo registro de termos salvo com sucesso!');
      }, () => {
        
      },
    );
  }

  showDanger(text): void {
    this.toaster.open({
      text,
      caption: 'Ops!',
      type: 'danger',
      position: 'top-right'
    });
  }

  showSuccess(text): void {
    this.toaster.open({
      text,
      caption: 'OK!',
      type: 'success',
      position: 'top-right',
    });
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
