import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { PmeEntity } from '../entities/pme.entity';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PmeService extends ApiService<PmeEntity> {

  private deletedFieldsCouponsPme = '&delete=used_clients&delete=generated_client&delete=coupon.generate_kits&delete=coupon.generate_subscriptions' +
    '&delete=coupon.kits_discount&delete=coupon.pme&delete=coupon.target_clients&delete=coupon.subscriptions_discount';

  constructor(http: HttpClient) {
    super('pmes', http);
  }

  getDashboardData(pmeId): Promise<any> {
    return this.http
      .get(`${ this.url }/${ pmeId }/dashboard`)
      .pipe(
        map(response => {
          return response as any;
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  getCharges(id: number): Promise<any> {
    return this.http.get(`${ this.url }/${ id }/charges${ this.getParamsAsString() }`, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }

  includePayment(pmeId: number, chargeId: string, paymentDate: string): Promise<any> {
    return this.http.post(`${ this.url }/${ pmeId }/charges/${ chargeId }/paid/`, {
      id: pmeId,
      chargeId,
      paymentDate
    }).toPromise();
  }

  cancelPayment(pmeId: number, chargeId: string, paymentDate: string): Promise<any> {
    return this.http.post(`${ this.url }/${ pmeId }/charges/${ chargeId }/cancel/`, {
      id: pmeId,
      chargeId,
      paymentDate
    }).toPromise();
  }

  generateCharge(pmeId: number, chargeId: string, billingDate: string): Promise<any> {
    return this.http.post(`${ this.url }/${ pmeId }/charges/${ chargeId }/generate-bank-slip/`, {
      id: pmeId,
      chargeId,
      billingDate
    }).toPromise();
  }

  generateChargeConsultant(pmeId: number): Promise<any> {
    return this.http.post(`${ this.url }/${ pmeId }/generate-charge-consultant/`, {}).toPromise();
  }

  getCoupons(id: number): Promise<any> {
    return this.http.get(`${ this.url }/${ id }/coupons${ this.getParamsAsString() }`, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }

  getCouponsPme(id: number): Promise<any> {
    return this.http.get(`${ this.url }/${ id }/coupons${ this.getParamsAsString() }${ this.deletedFieldsCouponsPme }`, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
}
