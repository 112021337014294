import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ProviderService} from '../../../services';
import {MatColumnEntity} from '../../../entities/mat-column.entity';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {LogsPaginatedListEntity} from '../../../entities/paginated/logs-paginated-list.entity';
import {ProviderEntity} from '../../../entities/provider.entity';


@Component({
  selector: 'app-logs-provider',
  templateUrl: './logs-provider-modal.component.html',
  styleUrls: ['./logs-provider-modal.component.scss'],
  providers: [ProviderService],
})
export class LogsProviderModalComponent implements OnInit {
  loading: boolean;
  dataSource: any;
  displayedColumns: string[];
  columnNames: MatColumnEntity[];
  logsPaginatedList: LogsPaginatedListEntity;
  provider: ProviderEntity;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    private dialogRef: MatDialogRef<LogsProviderModalComponent>,
  ) {
    this.provider = data.provider;
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private tableConfig(): void {
    this.columnNames = [
      new MatColumnEntity('event', 'Evento'),
      new MatColumnEntity('description', 'Descrição'),
      new MatColumnEntity('createDate', 'Data'),
    ];
    this.displayedColumns = this.columnNames.map((x) => x.id);
  }
  ngOnInit(): void {
    this.tableConfig();
    this.search();
  }

  close(): void {
    this.dialogRef.close(false);
  }
  search(): void {
    this.loading = true;
    this.providerService.setParam('page', 1);
    this.providerService.logsByProviderId(this.provider.id).then(
      (data) => {
        this.loading = false;
        this.logsPaginatedList = new LogsPaginatedListEntity(data);
        this.dataSource = new MatTableDataSource(this.logsPaginatedList.getMatTableDataSource());
        setTimeout(() => this.dataSource.paginator = this.paginator);
      }, () => {
        this.loading = false;
      }
    );
  }
  changePage(page): void {
    this.loading = true;
    const {
      previousPageIndex,
      pageIndex,
    } = page;
    const previousData = this.logsPaginatedList.getResultsAlreadyLoadedByPage(pageIndex);
    if (!previousData) {
      this.providerService.setParam('page', pageIndex + 1);
      this.providerService.logsByProviderId(this.provider.id).then(
        (data: any) => {
          this.logsPaginatedList.update(previousPageIndex, pageIndex, data);
          this.dataSource.data = this.logsPaginatedList.getMatTableDataSource();
          this.loading = false;
        }, () => this.loading = false,
      );
    } else {
      this.logsPaginatedList.setResults(previousData);
      this.dataSource.data = this.logsPaginatedList.getMatTableDataSource();
      this.loading = false;
    }
  }
}
