// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://api-qas.matine.app/v1',
  // url: 'https://api.matine.app/v1',
  cep: 'https://api.postmon.com.br/v1/cep/',
  url_category: 'https://lacossaude.com/wp-json/wp/v2/categorias_app',
  extratc: {
    url: 'https://users-api.iron.fit',
    grant_type: 'password',
    client_id: 'etgtIB0O43saFI24MRkm2o3mr3l35UY',
    client_secret: 'etgtIB0O43saFI24MRkm2o3mr3l35UY',
    username: 'e3be07ff-9b83-482f-b934-670244469864',
    password: 'ee19736b-c31f-46ff-939e-ae9046c63ac7'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
