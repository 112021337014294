<div class="module-register font-trovi">
  <div class="container  mt-4">
    <div class="row">
      <div class="col-12 title">
        <button mat-icon-button (click)="close()" matTooltip="Voltar" class="btn-back">
          <mat-icon class="orange-matine">keyboard_backspace</mat-icon>
        </button>
        <h3 *ngIf="!loading">{{ providerSchedule?.isPerformed ? 'Agendamento' : 'Confirmação de Agendamento'}}</h3>
      </div>
    </div>
    <hr>
    <div *ngIf="!loading">
      <form id="form-add-event" [formGroup]="formEvent" (submit)="onSubmit()">
        <div class="col-12">
          <div class="row">
            <div class="col-9">
              <div class="row">
                <div class="col-md-5">
                  <label for="date">Data:</label>
                  <input id="date" [formGroup]="formEvent" [formControlName]="'date'" type="date" class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="startHour">Início:</label>
                  <input id="startHour" [formGroup]="formEvent" [formControlName]="'startHour'" type="time"
                    class="form-control">
                </div>
                <div class="col-md-1 text-center mt-5">
                  <span class="between">até</span>
                </div>
                <div class="col-md-3">
                  <label for="endHour">Fim:</label>
                  <input id="endHour" [formGroup]="formEvent" [formControlName]="'endHour'" type="time"
                    class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="type">Tipo Cliente:</label>
                  <div class="w-100" [formGroup]="formEvent">
                    <ng-select id="type" [items]="clientTypes" [bindValue]="'id'" [bindLabel]="'label'" (change)="onChangeClientType()"
                      [formControlName]="'type'">
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="phone">Celular:</label>
                  <input type="text" [dropSpecialCharacters]="false" [formControlName]="'phone'" mask="(00) 00000-0000" [formGroup]="formEvent"
                    id="phone" class="form-control">
                </div>
              </div>
            </div>

            <div *ngIf="clientTypeSelected === 'app'" class="col-3 top-0">
              <div class="card">
                <div class="card-body p-0">
                  <div class="text-center">
                    <img src="{{clientImg}}" class="img-avatar">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="clientTypeSelected !== 'app'">
          <div class="col-12">
            <div class="row">
              <div class="col">
                <label for="client">Cliente:</label>
                <input [formControlName]="'particularClientName'" [formGroup]="formEvent" id="client"
                  class="form-control">
              </div>
              <div class="col">
                <label for="healthPlan">Convênio:</label>
                <input [formControlName]="'healthPlan'" [formGroup]="formEvent" id="healthPlan" class="form-control">
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="procedure">Procedimento:</label>
            <input [formControlName]="'particularProcedure'" [formGroup]="formEvent" id="procedure"
              class="form-control">
          </div>
        </div>

        <div *ngIf="clientTypeSelected === 'app'">
          <div class="row">
            <div class="col-12">
              <div class="col-12">
                <label for="client">Selecione o cliente:</label>
                <app-async-select-client [formGroup]="formEvent" [formControlName]="'client'"
                  (clientsLoaded)="onClientsLoaded($event)" id="client" (click)="onChangeClient()"
                  (keyup.enter)="onChangeClient()">
                </app-async-select-client>
              </div>
              <div class="col-12">
                <label for="procedures">Procedimento:</label>
                <app-async-select-procedure [formGroup]="formEvent" [filterIds]="data.providersProcedureIds"
                  [multiple]="true" [formControlName]="'procedures'" (proceduresLoaded)="procedures = $event"
                  id="procedures">
                </app-async-select-procedure>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col" *ngIf="clientTypeSelected === 'app'">
              <label for="client">PIN:</label>
              <input [formControlName]="'pin'" mask="0 0 0 0" [formGroup]="formEvent" id="pin" class="form-control">
            </div>
            <div class="col text-center">
              <div class="row">
                <div class="col">
                  <label class="col-12" for="isConfirmed">Confirmado ?</label>
                  <mat-slide-toggle [formControl]="formEvent.get('isConfirmed')" (click)="onSubmit(true)" id="isConfirmed">
                  </mat-slide-toggle>
                </div>
                <div class="col">
                  <label class="col-12" for="isPerformed">Realizado ?</label>
                  <mat-slide-toggle [formControl]="formEvent?.controls['isPerformed']"id="isPerformed">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <hr>
      <div *ngIf="!providerSchedule?.isPerformed">
        <div class="row">
          <div class="col text-right">
            <button mat-raised-button class="mr-2 black-trovi-button text-white" type="button" focusable="false"
                    (click)="deleteProviderSchedule()" [disabled]="btnRemoveDisabled()" *ngIf="clientTypeSelected !== 'app'">
              <i class="fa fa-times fa-lg mat-icon text-white"></i> Remover
            </button>
            <button mat-raised-button class="mr-2 black-trovi-button text-white" type="button" focusable="false"
                    (click)="cancelProviderSchedule()" [disabled]="btnRemoveDisabled()" *ngIf="clientTypeSelected === 'app'">
              <i class="fa fa-times fa-lg mat-icon text-white"></i> Cancelar
            </button>
            <button form="form-add-event" (click)="onPerformProcedure()" [disabled]="btnPerformProcedureDisabled()"
                    color="primary" class="btn btn-block" type="submit" mat-raised-button class="btn-confirm">
              <i class="fa fa-calendar-check-o fa-lg mat-icon text-white"></i> Realizar Procedimennto</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
