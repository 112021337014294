import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-schedule-notification-dialog-component',
  templateUrl: './confirm-schedule-notification-dialog.component.html',
  styleUrls: ['./confirm-schedule-notification-dialog.component.scss']
})
export class ConfirmScheduleNotificationDialogComponent implements OnInit {
  form: FormGroup;
  data = { confirmed: false, date: null, time: null };
  today = new Date();
  now = moment(this.today);
  isValidTime = true;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ConfirmScheduleNotificationDialogComponent>,
  ) {
  }

  ngOnInit() {
    this.now.add(3, 'hours');
    this.form = this.formBuilder.group({
      date: [this.now.toDate(), null],
      time: [this.now.format('HH[:]mm'), null]
    });
    setTimeout(() => {
      document.getElementById('time').focus();
    }, 1000);
  }
  confirm() {
    this.data.confirmed = true;
    this.data.date = moment(this.form.get('date').value);
    this.data.time = this.form.get('time').value;
    this.dialogRef.close(this.data);
  }
  cancel() {
    this.data.confirmed = false;
    this.data.date = null;
    this.data.time = null;
    this.dialogRef.close(this.data);
  }
  isValidDate() {
    return !moment(this.form.get('date').value).isValid();
  }
  verifyTime() {
    if (moment(this.form.get('date').value).toDate().toString().toLowerCase() === 'invalid date') {
      this.isValidTime = false;
    }
    const textField = this.form.get('time').value;
    const schedule = moment(this.form.get('date').value);
    schedule.hour(parseInt(textField.split(':')[0], 10));
    schedule.minute(parseInt(textField.split(':')[1], 10));
    const diff = moment(schedule).diff(moment(this.now), 'minutes', false);
    this.isValidTime = diff >= 0;
  }
}
