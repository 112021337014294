import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {KitEntity} from '../../../entities/kit.entity';
import {ClientService} from '../../../services/client.service';

@Component({
  selector: 'app-async-select-kit-checkout',
  templateUrl: './async-select-kit-checkout.component.html',
  styleUrls: ['./async-select-kit-checkout.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectKitCheckoutComponent),
      multi: true,
    },
    ClientService
  ],
})
export class AsyncSelectKitCheckoutComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() clientId = 0;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() kitsLoaded = new EventEmitter<Array<KitEntity>>();

  loading: boolean;
  kits: Array<any> = [];
  input: string;
  constructor(
    private clientService: ClientService,
  ) {
  }

  private loadKits(page = 1): void {
    this.loading = true;
    this.clientService.setParam('page', page);
    const providerId = this.clientService.getUserLogged()?.provider?.id.toString();
    this.clientService.setParam('provider', providerId);
    this.clientService.allCheckoutsByClientId(this.clientId.toString()).then(
      (data: any) => {
        data.results.forEach(it => {
          it.items = it.items.filter(f => f.provider?.id.toString() === providerId && !f.providerSchedule?.isPerformed)
            .map(i => ({
            ...i,
            code: it.code,
            checkout: {
              id: it.id,
            },
            name: `${it.code} - ${i.kit.name}`
          }));
          this.kits = [...this.kits, ...it.items];
        });
        if (page < data.numPages) {
          this.loadKits(page + 1);
          return;
        }
        this.kitsLoaded.emit(this.kits);
        this.loading = false;
      }, () => {
        this.kitsLoaded.emit(this.kits);
        this.loading = false;
      },
    );
  }
  ngOnInit(): void {
    this.loadKits();
  }
  onChange: any = () => { };
  onTouch: any = () => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }
}
