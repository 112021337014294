import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, FormControlName, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UserEntity} from '../../../entities';
import {UserService} from '../../../services';
import {ProfileType} from '../../../types/profile.type';

@Component({
  selector: 'app-async-select-user',
  templateUrl: './async-select-user.component.html',
  styleUrls: ['./async-select-user.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectUserComponent),
      multi: true,
    }, UserService,
  ],
})
export class AsyncSelectUserComponent implements OnInit, ControlValueAccessor {
  @Input() profile?: ProfileType;
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() usersLoaded = new EventEmitter<Array<UserEntity>>();
  loading: boolean;
  users: Array<UserEntity> = [];
  input: string;
  constructor(
    private userService: UserService,
  ) {
  }
  private loadUsers(page = 1): void {
    this.loading = true;
    if (this.profile) {
      this.userService.setParam('profile', this.profile);
    }
    this.userService.setParam('page', page);
    this.userService.findAll().then(
      (data: any) => {
        this.users = [...this.users, ...data.results];
        if (this.users.length < data.count) {
          this.loadUsers(page + 1);
        }
        this.users = this.users.map((user) => {
          const u = new UserEntity(user.id);
          u.setRawValue(user);
          return u;
        });
        this.loading = false;
        this.usersLoaded.emit(this.users);
      }, () => {
        this.loading = false;
        this.usersLoaded.emit([] as Array<UserEntity>);
      },
    );
  }
  ngOnInit(): void {
    this.loadUsers();
  }
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }
}
