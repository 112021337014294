import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {map} from 'lodash';

@Component({
  selector: 'app-required-field',
  templateUrl: './required-field.component.html',
  styleUrls: ['./required-field.component.scss'],
})
export class RequiredFieldComponent implements OnInit {
  @Input() field: FormGroup;
  mappedErrors = {
    required: 'Preenchimento obrigatório',
    incorrectValue: 'Preenchimento inválido',
    mask: 'Formato inválido',
  };
  constructor(
  ) {
  }
  ngOnInit(): void {
  }
  public getError(): string {
    let errors = [];
    if (this.field.invalid && (this.field.dirty || this.field.touched) && this.field.errors) {
      errors = map(this.field.errors, (value, key) => ({ key, value }));
    }
    if (errors[0].key === 'mask') {
      return `${this.mappedErrors[errors[0].key]} ${errors[0].value.requiredMask}`;
    }
    return this.mappedErrors[errors[0].key];
  }
}
