import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {ProviderService} from '../../../services';
import {ProviderEntity} from '../../../entities/provider.entity';
import {Formatter} from '../../../utils/formatter';

@Component({
  selector: 'app-async-select-provider',
  templateUrl: './async-select-provider.component.html',
  styleUrls: ['./async-select-provider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectProviderComponent),
      multi: true,
    },
    ProviderService
  ],
})
export class AsyncSelectProviderComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() providersLoaded = new EventEmitter<Array<ProviderEntity>>();

  loading: boolean;
  providers: Array<ProviderEntity> = [];
  input: string;
  constructor(
    private providerService: ProviderService,
  ) {
  }

  private loadProviders(page = 1): void {
    this.loading = true;
    this.providerService.setParam('page', page);
    this.providerService.findAll().then(
      (data: any) => {
        this.providers = [...this.providers, ...data.results];
        if (this.providers.length < data.count) {
          this.loadProviders(page + 1);
          return;
        }
        this.providersLoaded.emit(this.providers);
        this.loading = false;
      }, () => {
        this.providersLoaded.emit(this.providers);
        this.loading = false;
      },
    );
  }
  ngOnInit(): void {
    this.loadProviders();
  }
  onChange: any = () => { };
  onTouch: any = () => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }
  searchFn(term: string, provider: ProviderEntity): boolean {
    return (provider.cnpj.includes(term) || provider.tradingName?.toLowerCase().includes(term.toLowerCase()));
  }
  getCnpj(cnpj: string): string {
    return Formatter.cnpj(cnpj);
  }
}
