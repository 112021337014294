import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BlogsEntity } from 'src/app/shared/entities/blogs.entity';
import { BlogsService } from 'src/app/shared/services/blogs.service';

@Component({
  selector: 'app-async-select-blog',
  templateUrl: './async-select-blog.component.html',
  styleUrls: ['./async-select-blog.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncBlogComponent),
      multi: true,
    },
    BlogsService,
  ],
})
export class AsyncBlogComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  loading: boolean;
  groups: Array<BlogsService> = [];
  input: string;
  @Output() blogSelected = new EventEmitter<BlogsEntity>();
  @Output() clear = new EventEmitter<boolean>();

  constructor(
    private blogService: BlogsService,
  ) {
  }
  private loadGroups(page = 1): void {
    this.loading = true;
    this.blogService.setParam('page', page);
    this.blogService.findAll().then(
      (data: any) => {
        console.log(data)
        this.groups = [...this.groups, ...data.results];
        if (this.groups.length < data.count) {
          this.loadGroups(page + 1);
        }
        this.loading = false;
      }, () => this.loading = false,
    );
  }
  ngOnInit(): void {
    this.loadGroups();
  }
  onChange: any = () => { };
  onTouch: any = () => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }

  selected(item: BlogsEntity) {
    console.log('veio selected')
    this.blogSelected.emit(item);
  }

  clearInput($event: any) {
    this.clear.emit(true);
  }
}
