<div class="module-register font-trovi">
  <div class="container mt-4">
    <div class="row">
      <div class="col-12 title">
        <button mat-icon-button (click)="close()" matTooltip="Voltar" class="btn-back">
          <mat-icon class="orange-matine">keyboard_backspace</mat-icon>
        </button>
        <h3>Solicitação de Agendamento</h3>
      </div>
    </div>
    <hr>
    <form id="form-add-event" [formGroup]="formEvent" (submit)="onSubmit()">
      <div class="col-12">
        <div class="row">
          <div class="col-9">
            <div class="row">
              <div class="col-md-5">
                <label for="type">Tipo Cliente:</label>
                <div class="w-100">
                  <ng-select
                    [items]="clientTypes"
                    [bindValue]="'id'"
                    [bindLabel]="'label'"
                    (change)="onChangeClientType()"
                    formControlName="type"
                    id="type"
                  >
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5">
                <label for="date">Data:</label>
                <input id="date" formControlName="date" type="date" class="form-control">
              </div>
              <div class="col-md-3">
                <label for="startHour">Início:</label>
                <input id="startHour" formControlName="startHour" type="time" class="form-control">
              </div>
              <div class="col-md-1 text-center mt-5">
                <span class="between">até</span>
              </div>
              <div class="col-md-3">
                <label for="endHour">Fim:</label>
                <input id="endHour" formControlName="endHour" type="time" class="form-control">
              </div>
            </div>
          </div>

          <div *ngIf="clientTypeSelected?.id === 'app'" class="col-3 top-0">
            <div class="card">
              <div class="card-body p-0">
                <div class="">
                  <img [src]="clientImg" class="img-client" width="165" height="167" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="clientTypeSelected?.id === 'local'">
        <div class="col-12">
          <div class="row">
            <div class="col-9">
              <label for="client">Cliente:</label>
              <input formControlName="particularClientName" id="client" class="form-control">
            </div>
            <div class="col-3">
              <label for="phone">Celular:</label>
              <input type="text" [dropSpecialCharacters]="false" formControlName="phone" mask="(00) 00000-0000" id="phone" class="form-control">
            </div>
            <div class="col">
              <label for="healthPlan">Convênio:</label>
              <input formControlName="healthPlan" id="healthPlan" class="form-control">
            </div>
          </div>
        </div>
        <div class="col-12">
          <label for="procedure">Procedimento:</label>
          <input formControlName="particularProcedure" id="procedure" class="form-control">
        </div>
      </div>

      <div *ngIf="clientTypeSelected?.id === 'app'">
        <div class="row">
          <div class="col-12">
            <div class="row pl-3 pr-3">
              <div class="col-9">
                <label for="client">Selecione o cliente:</label>
                <app-async-select-client
                  id="client"
                  [formGroup]="formEvent"
                  [formControlName]="'client'"
                  (clientsLoaded)="onClientsLoaded($event)"
                  (click)="onChangeClient()"
                  (keyup.enter)="onChangeClient()"
                ></app-async-select-client>
              </div>
              <div class="col-md-3">
                <label for="phone">Celular:</label>
                <input type="text" [dropSpecialCharacters]="false" formControlName="phone" mask="(00) 00000-0000" id="phone" class="form-control">
              </div>
              <div class="col-12">
                <label for="procedures">Procedimento:</label>
                <app-async-select-procedure [formGroup]="formEvent" [filterIds]="data.providersProcedureIds"
                                            [multiple]="true" [formControlName]="'procedures'" (proceduresLoaded)="procedures = $event"
                                            id="procedures">
                </app-async-select-procedure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <hr>
    <div class="row pl-3 pr-3">
      <div class="offset-md-8 col-md-2">
        <button
          class="btn-actions"
          mat-raised-button
          type="button"
          color="warn"
          (click)="refuse()"
        >
          <i class="fa fa-calendar-times-o fa-lg i-actions"></i> Recusar
        </button>
      </div>
      <div class="col-md-2">
        <button form="form-add-event" color="primary" class="btn btn-block btn-actions btn-confirm" [disabled]="formEvent?.invalid"
                type="submit" mat-raised-button>
          <i class="fa fa-calendar-check-o fa-lg i-actions"></i> Aceitar</button>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
