import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProviderEntity } from '../../../entities/provider.entity';
import { Formatter } from '../../../utils/formatter';
import { ProvidersSubscriptionService } from '../../../services/providers-subscription.service';
import { ProviderSubscriptionEntity } from '../../../entities/provider-subscription.entity';

@Component({
  selector: 'app-async-select-provider-subscription',
  templateUrl: './async-select-provider-subscription.component.html',
  styleUrls: ['./async-select-provider-subscription.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectProviderSubscriptionComponent),
      multi: true,
    },
    ProvidersSubscriptionService
  ],
})
export class AsyncSelectProviderSubscriptionComponent implements OnInit {
  @Input() multiple = false;
  @Input() bindLabel = 'name';
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() providersLoaded = new EventEmitter<Array<ProviderSubscriptionEntity>>();
  @Output() providerSelected = new EventEmitter<ProviderSubscriptionEntity>();
  @Output() clear = new EventEmitter<boolean>();

  loading: boolean;
  providers: Array<ProviderSubscriptionEntity> = [];
  input: string;

  constructor(
    private providerSubscriptionService: ProvidersSubscriptionService,
  ) {
  }

  private loadProviders(page = 1): void {
    this.loading = true;
    this.providerSubscriptionService.setParam('page', page);
    this.providerSubscriptionService.findAll().then(
      (data: any) => {
        console.log(data);
        this.providers = [...this.providers, ...data.results];
        if (this.providers.length < data.count) {
          this.loadProviders(page + 1);
          return;
        }
        this.providersLoaded.emit(this.providers);
        this.loading = false;
      }, () => {
        this.providersLoaded.emit(this.providers);
        this.loading = false;
      },
    );
  }

  ngOnInit(): void {
    this.loadProviders();
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: string): void {
    this.input = input;
  }

  searchFn(term: string, provider: ProviderEntity): boolean {
    return (provider.cnpj.includes(term) || provider.tradingName?.toLowerCase().includes(term.toLowerCase()));
  }

  getCnpj(cnpj: string): string {
    return Formatter.cnpj(cnpj);
  }

  selected(item: ProviderSubscriptionEntity) {
    console.log(item);
    this.providerSelected.emit(item);
  }

  clearInput($event: any) {
    this.clear.emit(true);
  }
}
