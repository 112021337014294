import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
@Component({
  selector: 'app-confirm-reason-modal',
  templateUrl: './confirm-reason-modal.component.html',
  styleUrls: ['./confirm-reason-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmReasonModalComponent implements OnInit {
  text: string;
  form: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<ConfirmReasonModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { text: string; },
  ) {
    this.text = data.text;
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      reason: new FormControl(null, Validators.required),
    });
  }
  onSubmit(): void {
    const { reason } = this.form.getRawValue();
    this.dialogRef.close({confirmed: true, reason });
  }
  close(): void {
    this.dialogRef.close({confirmed: false, reason: null});
  }
}
