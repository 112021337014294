import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forEach } from 'lodash';
import { ApiService } from './api.service';
import {catchError, map} from 'rxjs/operators';
import {UserEntity} from '../entities';
import {ILogin} from '../interfaces';
import {IProfile} from '../interfaces/profile.interface';

@Injectable()
// @ts-ignore
export class ProviderTypeService extends ApiService<ProviderEntity> {
  constructor(http: HttpClient) {
    super('provider-types', http);
  }
}
