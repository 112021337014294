import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CompanyEntity} from '../../../entities/company.entity';
import {CompanyService} from '../../../services/company.service';
import { ProviderEntity } from 'src/app/shared/entities/provider.entity';
import { ProviderService } from 'src/app/shared/services';

@Component({
  selector: 'app-async-select-company',
  templateUrl: './async-select-company.component.html',
  styleUrls: ['./async-select-company.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectCompanyComponent),
      multi: true,
    },
    ProviderService,
  ],
})
export class AsyncSelectCompanyComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  loading: boolean;
  providers: Array<ProviderEntity> = [];
  input: string;
  constructor(
    private providerService: ProviderService,
  ) {
  }
  private loadCompanies(page = 1): void {
    this.loading = true;
    this.providerService.setParam('page', page);
    this.providerService.findAll().then(
      (data: any) => {
        this.providers = [...this.providers, ...data.results];
        if (this.providers.length < data.count) {
          this.loadCompanies(page + 1);
        }
        this.loading = false;
      }, () => this.loading = false,
    );
  }
  private loadCompany(id): void {
    if (!id) {
      return;
    }
    this.providerService.findById(id).then(
      (data: any) => {
        this.providers = [ data ];
      },
    );
  }
  ngOnInit(): void {
    if (this.providerService.getUserLogged()?.isAdmin()) {
      this.loadCompanies();
    } else {
      this.loadCompany(this.providerService.getUserLogged()?.provider?.id);
    }
  }
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }
}
