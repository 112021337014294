import { Component, OnInit, Inject } from '@angular/core';
import { ProcedureEntity } from 'src/app/shared/entities/procedure.entity';
import * as moment from 'moment';
import { get } from 'lodash';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ProviderScheduleEntity } from 'src/app/shared/entities/provider-schedule.entity';
import { ProvidersScheduleService } from 'src/app/shared/services/providers-schedule.service';
import { ClientEntity } from 'src/app/shared/entities/client.entity';
import { Toaster } from 'ngx-toast-notifications';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ConfirmYesNoModalComponent} from '../confirm-yes-no-modal/confirm-yes-no-modal.component';

@Component({
  selector: 'app-confirm-schedule-event-modal',
  templateUrl: './confirm-schedule-event-modal.component.html',
  styleUrls: ['./confirm-schedule-event-modal.component.scss'],
  providers: [
    ProvidersScheduleService
  ],
})
export class ConfirmScheduleEventModalComponent implements OnInit {

  loading: boolean;
  formDisabled: boolean;
  pinDisabled: boolean;
  procedures: Array<ProcedureEntity> = [];
  formEvent: FormGroup;
  clientTypes = [];
  clients: Array<ClientEntity>;
  imgDefault = '../../../../assets/images/default-user.png';
  clientImg = this.imgDefault;
  clientSelected: ClientEntity;
  clientTypeSelected: string;
  providerSchedule: ProviderScheduleEntity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmScheduleEventModalComponent>,
    private toaster: Toaster,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private providerScheduleService: ProvidersScheduleService
  ) {
  }
  private buildForm(): void {
    const formControl = (value = null, disabled = false, validators = [Validators.required]) => {
      return new FormControl({value, disabled}, validators);
    };
    const nullDisabledRequired = () => formControl(null, true, [Validators.required]);
    const nullEnabledRequired = () => formControl(null, false, [Validators.required]);
    const nullDisabledNotRequired = () => formControl(null, true);

    this.formEvent = this.fb.group({
      date: nullDisabledRequired(),
      startHour: nullDisabledRequired(),
      endHour: nullDisabledRequired(),
      client: nullDisabledRequired(),
      procedures: nullDisabledRequired(),
      phone: nullDisabledRequired(),
      type: nullDisabledRequired(),
      isConfirmed: nullEnabledRequired(),
      isPerformed: nullDisabledRequired(),
      pin: nullEnabledRequired(),
      particularProcedure: nullDisabledRequired(),
      particularClientName: nullDisabledRequired(),
      healthPlan: nullDisabledNotRequired()
    });
  }
  ngOnInit(): void {
    this.buildForm();
    this.clientTypes = [{
      id: 'app',
      label: 'Cliente App',
    }, {
      id: 'local',
      label: 'Cliente Externo (Particular)',
    }];
    this.getProviderScheduleById();
  }

  getProviderScheduleById() {
    this.loading = true;
    this
      .providerScheduleService
      .findById(this.data.providerScheduleId.toString())
      .then(
        (providerSchedule) => {
          this.providerSchedule = new ProviderScheduleEntity(providerSchedule.id);
          this.providerSchedule.setRawValue(providerSchedule);
          this.updateForm();
          this.loading = false;
        }, () => {
          this.loading = false;
        }
      );
  }

  updateForm(): void {
    const getValue = (key) => get(this.providerSchedule, key)
    this.clientImg = this.imgDefault;
    this.formDisabled = !!this.providerSchedule.isConfirmed;
    this.clientTypeSelected = this.clientTypes.find(f => f.id === this.providerSchedule.type)?.id;

    this.formEvent.get('date').setValue(moment(getValue('startDate')).format('YYYY-MM-DD'));
    this.formEvent.get('startHour').setValue(moment(getValue('startDate')).format('HH:mm:ss'));
    this.formEvent.get('endHour').setValue(moment(getValue('endDate')).format('HH:mm:ss'));
    this.formEvent.get('phone').setValue(this.providerSchedule.phone);
    this.formEvent.get('type').setValue(this.providerSchedule.type);
    this.formEvent.get('isConfirmed').setValue(this.providerSchedule.isConfirmed);

    if (getValue('type') === 'app') {
      this.pinDisabled = !!getValue('checkout.pin');

      this.clientSelected = this.providerSchedule.client;
      if (this.clientSelected.image?.length) {
        this.clientImg = this.clientSelected.image;
      }
      this.formEvent.get('client').setValue(this.clientSelected?.id);
      this.formEvent.get('procedures').setValue(this.providerSchedule.procedures.map(p => p.id));
      this.formEvent.get('isPerformed').setValue(this.providerSchedule.isPerformed);

      // this.formEvent.get('pin').setValue(null);
      // if (this.pinDisabled) {
      //   this.formEvent.get('pin').disable();
      // }
    } else {
      this.formEvent.get('particularProcedure').setValue(this.providerSchedule.particularProcedure);
      this.formEvent.get('particularClientName').setValue(this.providerSchedule.particularClientName);
      this.formEvent.get('healthPlan').setValue(this.providerSchedule.healthPlan);

      if (this.formDisabled) {
        this.formEvent.get('date').disable();
        this.formEvent.get('startHour').disable();
        this.formEvent.get('endHour').disable();
        this.formEvent.get('phone').disable();
        this.formEvent.get('type').disable();
        this.formEvent.get('particularProcedure').disable();
        this.formEvent.get('particularClientName').disable();
        this.formEvent.get('healthPlan').disable();
      }

      // this.formEvent.get('pin').setValue(this.providerSchedule.checkout?.pin);
      this.formEvent.get('isPerformed').setValue(this.providerSchedule.isPerformed);
      // this.formEvent.get('pin').disable();
      this.formEvent.get('isPerformed').disable();
      if (this.btnSaveDisabled()) {
        this.formEvent.get('isConfirmed').disable();
      }
    }
    if (this.formEvent.get('isPerformed').value) {
      this.formEvent.get('isConfirmed').disable();
    }
  }

  onClientsLoaded = (clients: Array<ClientEntity>) => {
    this.clients = clients;
    this.onChangeClient();
  }

  onChangeClient(): void {
    const clientId = this.formEvent.get('client')?.value;

    if (!clientId) {
      this.clientImg = this.imgDefault;
      this.clientSelected = null;
    }
    else if (clientId !== this.clientSelected?.id) {
      if (this.clients) {
        this.clientSelected = this.clients.find(client => client.id === clientId);
        this.formEvent.get('phone').setValue(this.clientSelected?.phone);
        this.clientImg = this.clientSelected.image && this.clientSelected.image !== '' ?
          this.clientSelected.image : this.imgDefault;
      }
    }
  }

  onChangeClientType(): void {
    this.clientImg = this.imgDefault;
    this.clientSelected = null;
    this.clientTypeSelected = this.formEvent.get('type').value;
    this.formEvent.get('phone').setValue('');

    this.formEvent.get('particularClientName').setValue(null);
    this.formEvent.get('healthPlan').setValue(null);
    this.formEvent.get('particularProcedure').setValue(null);
    this.formEvent.get('client').setValue(null);
    this.formEvent.get('procedures').setValue([]);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  onSubmit = (fromIsConfirmedFlag = false) => {
    const {
      isConfirmed,
      pin,
    } = this.formEvent.getRawValue();
    if (!fromIsConfirmedFlag) {
      if (isConfirmed && pin) {
        return this.showInfo('Não é possivel vincular um PIN a um agendamento não confirmado.');
      }
    } else {
      if (!isConfirmed && pin) {
        return this.showInfo('Não é possivel vincular um PIN a um agendamento não confirmado.');
      }
    }

    this.loading = true;

    this.providerSchedule.client = this.clientSelected;
    if (this.providerSchedule.client) {
      this.providerSchedule.procedures = this.procedures.filter(p => this.formEvent.get('procedures').value.includes(p.id));
    } else {
      this.providerSchedule.procedures = [];
    }

    this.providerSchedule.provider = this.data.provider;
    this.providerSchedule.type = this.formEvent.get('type').value;
    this.providerSchedule.phone = this.formEvent.get('phone').value;
    this.providerSchedule.particularClientName = this.formEvent.get('particularClientName')?.value;
    this.providerSchedule.particularProcedure = this.formEvent.get('particularProcedure')?.value;
    this.providerSchedule.healthPlan = this.formEvent.get('healthPlan')?.value;
    this.providerSchedule.startDate = moment(`${this.formEvent.get('date').value} ${this.formEvent.get('startHour').value}`).format('YYYY-MM-DD[T]HH:mm:ss');
    this.providerSchedule.endDate = moment(`${this.formEvent.get('date').value} ${this.formEvent.get('endHour').value}`).format('YYYY-MM-DD[T]HH:mm:ss');
    if (this.providerSchedule?.checkout) {
      this.providerSchedule.checkout.pin = this.formEvent.get('pin')?.value || null;
    }
    if (fromIsConfirmedFlag) {
      this.providerSchedule.isConfirmed = !this.formEvent.get('isConfirmed')?.value;
    } else {
      this.providerSchedule.isConfirmed = !!this.formEvent.get('isConfirmed')?.value;
    }
    this.providerSchedule.isActive = true;
    this.providerSchedule.isAccepted = true;
    this.providerSchedule.isUserApp = false;

    this.providerScheduleService
      .save(this.providerSchedule)
      .then(
        () => {
          this.showSuccess('Alteração realizada com sucesso');
          this.dialogRef.close(true);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  onPerformProcedure(): void {
    this.loading = true;

    if (this.providerSchedule.type === 'app') {
      if (!this.formEvent.get('pin')?.value) {
        return this.showInfo('Procedimento de clientes trovi são liberados somente com PIN.');
      } else {
        if (this.providerSchedule?.checkout) {
          this.providerSchedule.checkout.pin = this.formEvent.get('pin')?.value;
        }
      }
    }

    this.providerScheduleService
      .save({
        ...this.providerSchedule,
        isPerformed: true,
        isConfirmed: true
      })
      .then(
        () => {
          this.showSuccess('Alteração realizada com sucesso');
          this.dialogRef.close(true);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  deleteProviderSchedule = () => {
    this.loading = true;
    this
      .providerScheduleService
      .save({ ...this.providerSchedule, isActive: false })
      .then(() => {
        this.loading = false;
        this.showSuccess('Consulta removida com sucesso!');
        this.dialogRef.close(true);
      }, () => this.loading = false
      );
  }
  cancelProviderSchedule = () => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Deseja confirmar o cancelamento deste agendamento?',
    };
    const dialogRef = this.dialog.open(ConfirmYesNoModalComponent , dialogConfig);
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.loading = true;
        this
          .providerScheduleService
          .cancellation(this.providerSchedule.id)
          .then(() => {
              this.loading = false;
              this.showSuccess('Agendamento cancelado com sucesso!');
              this.dialogRef.close(true);
            }, () => this.loading = false
          );
      }
    });
  }
  btnRemoveDisabled(): boolean {
    return (this.providerSchedule.isPerformed);
  }

  btnSaveDisabled(): boolean {
    if (this.formEvent.get('type').value === 'app') {
      if (this.formEvent.get('isConfirmed').value && this.formEvent.get('pin').value) {
        return true;
      }
    } else if (this.formEvent.get('isConfirmed').value) {
      return true;
    }
    return false;
  }

  btnPerformProcedureDisabled(): boolean {
    if (!moment().isSame(this.providerSchedule.startDate, 'day')) {
      return true;
    }
    const {
      type,
      isConfirmed,
      pin,
    } = this.formEvent.getRawValue();
    if (isConfirmed && moment(this.providerSchedule.startDate).isAfter(moment())) {
      if (type === 'app' && pin) {
        return false;
      }
      return false;
    }
    return this.providerSchedule.isPerformed;
  }

  showSuccess(text): void {
    this.toaster.open({
      text,
      caption: 'OK!',
      type: 'success',
      position: 'top-right'
    });
  }

  showInfo(text): void {
    this.toaster.open({
      text,
      caption: '',
      type: 'info',
      position: 'top-right'
    });
  }
}
