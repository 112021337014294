import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate.get(['itens por pág.', 'NEXT_PAGE', 'PREVIOUS_PAGE', 'OF_LABEL']).subscribe(translation => {
      const nextPage = 'próxima pág.';
      const itemsPerPage = 'itens por pág.';
      const previousPage = 'pág. anterior';
      this.itemsPerPageLabel = translation[itemsPerPage];
      this.nextPageLabel = translation[nextPage];
      this.previousPageLabel = translation[previousPage];
      this.changes.next();
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} até ${endIndex} de ${length}`;
  }
}
