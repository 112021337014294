import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ZipCode} from '../interfaces/zipcode-request.interface';

@Injectable()
export class UtilService {
  http: HttpClient;
  constructor(http: HttpClient) {
    this.http = http;
  }
  getAddressByZipcode(cep): Promise<ZipCode> {
    return this.http.get(`${environment.cep}${cep}`).pipe(
      map(response => {
        return response as object;
      }),
      catchError(this.handleError)
    ).toPromise();
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', environment.cep);
    return Promise.reject(error.message || error);
  }
}
