<div class="button-container" [formGroup]="formGroup">
  <ng-select
    [appendTo]="appendToBody ? 'body' : ''"
    [loading]="loading"
    [items]="providers"
    [bindValue]="'id'"
    [bindLabel]="'tradingName'"
    [multiple]="multiple"
    [searchFn]="searchFn"
    [formControlName]="formControlName"
  >
    <ng-template ng-option-tmp let-item="item">
      <span style="display: block">{{item.tradingName}}</span>
      <label style="display: block; margin-top: -3px; font-size: 16px"><small><b>{{getCnpj(item.cnpj)}}</b></small></label>
    </ng-template>
  </ng-select>
</div>
