import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {ConfirmScheduleEventModalComponent} from './confirm-schedule-event-modal.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AsyncSelectModule } from '../../async-select/async-select.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot(options),
    NgSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatSlideToggleModule,
    AsyncSelectModule
  ],
  declarations: [
    ConfirmScheduleEventModalComponent,
  ],
  exports: [
    ConfirmScheduleEventModalComponent,
  ],
})
export class ConfirmScheduleEventModalModule {}
