<div class="logs">
  <div class="header-modal">
    <div class="close" (click)="close()">&times;</div>
  </div>
  <div class="body-modal">

  </div>
  <div class="row" *ngIf="loading">
    <div class="col-md-12 text-center m-auto">
      <mat-progress-spinner diameter="50"
                            style="margin: auto"
                            mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>
  <div class="container-table" *ngIf="!loading">
    <mat-table [dataSource]="dataSource">
      <ng-container
        matColumnDef="{{ column.id }}"
        *ngFor="let column of columnNames">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ column.value }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element[column.id] }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns">
      </mat-row>
    </mat-table>
    <mat-paginator
      *ngIf="!!logsPaginatedList"
      [pageSize]="logsPaginatedList.itensPerPage"
      [pageIndex]="logsPaginatedList.pageIndex"
      [length]="logsPaginatedList.count"
      showFirstLastButtons
      (page)="changePage($event)"
    >
    </mat-paginator>
  </div>
</div>
