import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TeamEntity} from '../../../entities/team.entity';

@Component({
  selector: 'app-async-select-team',
  templateUrl: './async-select-team.component.html',
  styleUrls: ['./async-select-team.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectTeamComponent),
      multi: true,
    },
  ],
})
export class AsyncSelectTeamComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() teamsLoaded = new EventEmitter<Array<TeamEntity>>();

  loading: boolean;
  teams: Array<TeamEntity> = [];
  input: string;
  constructor(
  ) {
  }
  ngOnInit(): void {
  }
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }
}
