import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-yes-no-modal',
  templateUrl: './confirm-yes-no-modal.component.html',
  styleUrls: ['./confirm-yes-no-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmYesNoModalComponent implements OnInit {
  question: string;
  subtitle: string;
  constructor(
    private dialogRef: MatDialogRef<ConfirmYesNoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { question: string; subtitle: string },
  ) {
    this.question = data.question;
    this.subtitle = data.subtitle;
  }
  ngOnInit(): void {
  }
  confirm(): void {
    this.dialogRef.close(true);
  }
  close(): void {
    this.dialogRef.close(false);
  }
}
