import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ProviderService} from '../../../services';
import {MatColumnEntity} from '../../../entities/mat-column.entity';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {LogEntity} from '../../../entities/log.entity';
import * as moment from 'moment';

@Component({
  selector: 'app-logs-provider',
  templateUrl: './logs-subscription-modal.component.html',
  styleUrls: ['./logs-subscription-modal.component.scss'],
  providers: [ProviderService],
})
export class LogsSubscriptionModalComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[];
  columnNames: MatColumnEntity[];
  log: Array<LogEntity>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LogsSubscriptionModalComponent>,
  ) {
    this.log = data.log;
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private tableConfig(): void {
    this.columnNames = [
      new MatColumnEntity('oldValue', 'Valor Anterior R$'),
      // new MatColumnEntity('newValue', 'Valor atual R$'),
      new MatColumnEntity('oldDescription', 'Descrição Anterior'),
      new MatColumnEntity('createDate', 'Data'),
      new MatColumnEntity('user', 'Usuário'),
      new MatColumnEntity('oldDaysForFree', 'Dias'),
    ];
    this.displayedColumns = this.columnNames.map((x) => x.id);
  }
  ngOnInit(): void {
    this.tableConfig();
    this.dataSource = new MatTableDataSource(this.log.map((l) => ({
      ...l,
      createDate: l.createDate ? moment(l.createDate).format('DD/MM/YYYY [às] HH:mm') : '',
      user: l.user.name,
    })));
    this.dataSource.paginator = this.paginator;
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
