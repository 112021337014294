import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

import { catchError, map } from 'rxjs/operators';
import { UserEntity } from '../entities';
import { AdvancedUserSearchEntity } from '../entities/advanced-user-search.entity';
import { ClientEntity } from '../entities/client.entity';
import { keys } from 'lodash';
import { AdvancedUserSearchV2Entity } from '../entities/advanced-user-search-v2.entity';
import { CheckOutEntity } from '../entities/checkout.entity';
import { ProviderScheduleEntity } from '../entities/provider-schedule.entity';
import { ClientNotificationEntity } from '../entities/client-notification.entity';
import { ConvertToCSV } from '../utils/ConvertToCSV';
import { ClientSubscriptionEntity } from '../entities/client-subscription.entity';
import { PaginatedListEntity } from '../entities/paginated/paginated-list.entity';

@Injectable()
// @ts-ignore
export class ClientService extends ApiService<ClientEntity> {
  constructor(http: HttpClient) {
    super('clients', http);
  }

  allCheckoutsByClientId(id: string): Promise<CheckOutEntity[]> {
    return this.http
      .get(`${ this.url }/${ id }/checkouts/${ this.getParamsAsString() }`)
      .pipe(
        map(response => {
          return response as CheckOutEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  allNotificationsByClientId(id: string): Promise<ClientNotificationEntity[]> {
    return this.http
      .get(`${ this.url }/${ id }/notifications/${ this.getParamsAsString() }`)
      .pipe(
        map(response => {
          return response as ClientNotificationEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  allQuestionAnswerByClientId(id: string): Promise<ClientNotificationEntity[]> {
    return this.http
      .get(`${ this.url }/${ id }/question/${ this.getParamsAsString() }`)
      .pipe(
        map(response => {
          return response as ClientNotificationEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  allSchedulesByClientId(id: string): Promise<ProviderScheduleEntity[]> {
    return this.http
      .get(`${ this.url }/${ id }/schedules/${ this.getParamsAsString() }`)
      .pipe(
        map(response => {
          return response as ProviderScheduleEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  subscriptionsClientId(id: string): Promise<ClientSubscriptionEntity[]> {
    return this.http
      .get(`${ this.url }/${ id }/subscriptions-admin/${ this.getParamsAsString() }`)
      .pipe(
        map(response => {
          return response as ClientSubscriptionEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  nfesClientId(id: string): Promise<any[]> {
    return this.http
      .get(`${ this.url }/${ id }/subscriptions-admin-nfse/${ this.getParamsAsString() }`)
      .pipe(
        map(response => {
          return response as any[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  findAdvancedSearch(payload: AdvancedUserSearchEntity): Promise<Array<ClientEntity>> {
    return this.http
      .post(`${ this.url }/advanced_search/`, payload)
      .pipe(
        map(response => {
          return response as UserEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  questionsStatistics(clientsId: number[]): Promise<any> {
    return this.http
      .post(`${ this.url }/questions/statistics/${ this.getParamsAsString() }`, clientsId)
      .pipe(
        map(response => {
          return response as UserEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  findAdvancedSearchV2(payload: AdvancedUserSearchV2Entity): Promise<any> {
    return this.http
      .post(`${ this.url }/advanced-search-v2/${this.getParamsAsString()}`, payload)
      .pipe(
        map(response => {
          return response as UserEntity[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }

  exportUsersAppCsvBasic(users: Array<any>): void {
    const con = new ConvertToCSV();
    // let csv = 'ID, Nome, Sobrenome, Email, CPF, Telefone, Data de Nascimento, Gênero, Data de Cadastro, Empresa, Avatar1, Avatar2, Data do Questionário, Data de Registro Epharma, Online, Valor da Assinatura,  \n';
    // users.forEach((user) => {
    //   csv += con.convert(user);
    // });
    let csv = con.convert(users);
    const hiddenElement = document.createElement('a') as any;
    hiddenElement.href = 'data:text/csv;charset=UTF-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'usuários-app.csv';
    hiddenElement.click();
  }

  handlingAboutFoodChart(items: any, questionId): any {
    const item = items?.find((i) => i.id.toString() === questionId.toString());
    if (!item) {
      return;
    }
    const labels = [];
    const data = [];
    item.choices.forEach((choice) => {
      labels.push(choice.name);
      data.push(choice.count ? choice.count : 0);
    });
    const dataset = [
      {
        data,
        label: item.name,
        backgroundColor: [
          'rgba(34, 174, 187, 0.80)',
          'rgba(34,187,37,0.8)',
          'rgba(238, 66, 75, 0.80)',
          'rgba(251, 159, 61, 0.80)',
          'rgba(251,232,61,0.8)',
        ],
      },
    ];
    return { labels, data: dataset };

  }

  handlingImcChart(items: any): any {
    const mappedData = {
      magreza: 0,
      normal: 0,
      sobrepeso: 0,
      obesidade: 0,
      obesidadeGrave: 0,
    };
    items.forEach((value) => {
      if (value < 18.5) {
        mappedData.magreza++;
      } else if (value < 25) {
        mappedData.normal++;
      } else if (value < 30) {
        mappedData.sobrepeso++;
      } else if (value < 40) {
        mappedData.obesidade++;
      } else {
        mappedData.obesidadeGrave++;
      }
    });
    const data = [
      mappedData.magreza,
      mappedData.normal,
      mappedData.sobrepeso,
      mappedData.obesidade,
      mappedData.obesidadeGrave,
    ];
    const labels = [
      'Magreza',
      'Normal',
      'Sobrepeso',
      'Obesidade',
      'Obesidade Grave',
    ];
    const dataset = [
      {
        data,
        label: 'IMC',
        backgroundColor: [
          'rgba(34, 174, 187, 0.80)',
          'rgba(34,187,37,0.8)',
          'rgba(238, 66, 75, 0.80)',
          'rgba(251, 159, 61, 0.80)',
          'rgba(251,232,61,0.8)',
        ],
      },
    ];
    return { labels, data: dataset };

  }

  handlingGenderChart(values: Array<string>): any {
    console.log(values);
    const mappedData = {};
    const data = [];
    const buildMappedData = (gender, label) => {
      const amount = values.filter((n) => n === gender).length;
      mappedData[label] = amount;
      data.push(amount);
    };
    buildMappedData('male', 'Maculino');
    buildMappedData('female', 'Feminino');
    buildMappedData('others', 'Outros');
    const dataset = [
      {
        data,
        label: 'Gênero %',
        backgroundColor: [
          'rgba(34, 174, 187, 0.80)',
          'rgba(238, 66, 75, 0.80)',
          'rgba(251, 159, 61, 0.80)',
        ],
      },
    ];
    return { labels: keys(mappedData), data: dataset };

  }

  handlingAgeChart(values: Array<number>): any {
    const mappedData = {
      '81+': values.filter((n) => n >= 81).length,
    };
    const data = [values.filter((n) => n >= 81).length];
    const buildMappedData = (start, end) => {
      const amount = values.filter((n) => n >= start && n <= end).length;
      mappedData[`${ start }-${ end }`] = amount;
      data.push(amount);
    };
    buildMappedData(0, 10);
    buildMappedData(11, 20);
    buildMappedData(21, 30);
    buildMappedData(31, 40);
    buildMappedData(41, 50);
    buildMappedData(51, 60);
    buildMappedData(61, 70);
    buildMappedData(71, 80);
    const dataset = [
      {
        data,
        label: 'Faixa Etária %',
        backgroundColor: [
          'rgba(34, 174, 187, 0.80)',
          'rgba(34,187,67,0.8)',
          'rgba(235,243,22,0.8)',
          'rgba(238, 66, 75, 0.80)',
          'rgba(238,66,169,0.8)',
          'rgba(166,66,238,0.8)',
          'rgba(251, 159, 61, 0.80)',
          'rgba(61,70,251,0.8)',
          'rgba(6, 36, 59, 0.80)',
        ],
      },
    ];
    return { labels: keys(mappedData), data: dataset };

  }

  cancelSubscription(clientId: number): Promise<any> {
    return this.http
      .post(`${ this.url }/${clientId}/cancel-subscription/`, {})
      .pipe(
        map(response => {
          return response;
        }),
        catchError(this.handleError)
      ).toPromise();
  }
}
