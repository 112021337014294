import { BaseEntity } from '.';
import { BaseInterface } from '../interfaces/base.interface';
import { AddressEntity } from './address.entity';
import { AttendanceGroupsEntity } from './attendance-groups.entity';
import { BlogsEntity } from './blogs.entity';
import { PmeEntity } from './pme.entity';
import { TutorEntity } from './tutor.entity';

export class ClientEntity extends BaseEntity implements BaseInterface {
  constructor(id?: number) {
    super(id);
  }
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  cpf: string;
  phone: string;
  birthday: string;
  gender: string;
  address: AddressEntity;
  lastAccess: string;
  attendanceGroup: AttendanceGroupsEntity;
  blog: BlogsEntity;
  company: PmeEntity;
  createDate: string;
  password: string;
  online: boolean;
  subscriptionThird: boolean;
  isActive: boolean;
  image: string;
  label: string;
  subscriptionRenewalDatetime: string;
  subscriptionAcquisitionDatetime: string;
  totalValueSubscription: string;
  lastCheckoutDatetime: string;
  totalValueKit: string;
  totalValueKitTrovi: string;
  totalBuy: string;
  proceduresToPerform: number;
  // subscriptionNextPaymentDate: string;
  subscriptionValidTo: string;
  isDaysForFreeSubscription: boolean;
  isSubscriptionFee: boolean;
  tutor: TutorEntity;
  setRawValue(entity): void {
    if (entity) {
      const {
        firstName,
        lastName,
        fullName,
        email,
        company,
        subscriptionRenewalDatetime,
        subscriptionAcquisitionDatetime,
        subscriptionNextPaymentDate,
        cpf,
        image,
        phone,
        birthday,
        online,
        gender,
        address,
        createDate,
        lastAccess,
        totalBuy,
        proceduresToPerform,
        password,
        isActive,
        subscriptionThird,
        totalValueSubscription,
        lastCheckoutDatetime,
        totalValueKit,
        totalValueKitTrovi,
        subscriptionValidTo,
        isDaysForFreeSubscription,
        isSubscriptionFee,
        attendanceGroup,
        blog,
        tutor
      } = entity;

      this.firstName = firstName;
      this.company = company;
      this.lastName = lastName;
      this.firstName = firstName;
      this.lastName = lastName;
      this.subscriptionRenewalDatetime = subscriptionRenewalDatetime;
      // this.subscriptionNextPaymentDate = subscriptionNextPaymentDate;
      this.subscriptionAcquisitionDatetime = subscriptionAcquisitionDatetime;
      this.totalValueSubscription = totalValueSubscription;
      this.lastCheckoutDatetime = lastCheckoutDatetime;
      this.totalValueKit = totalValueKit;
      this.totalValueKitTrovi = totalValueKitTrovi;
      this.fullName = fullName;
      this.totalBuy = totalBuy;
      this.email = email;
      this.cpf = cpf;
      this.online = online;
      this.image = image;
      this.phone = phone;
      this.birthday = birthday;
      this.gender = gender;
      this.address = address;
      this.createDate = createDate;
      this.lastAccess = lastAccess;
      this.password = password;
      this.isActive = isActive;
      this.subscriptionValidTo = subscriptionValidTo;
      this.isDaysForFreeSubscription = isDaysForFreeSubscription;
      this.isSubscriptionFee = isSubscriptionFee;
      this.company = company;
      this.proceduresToPerform = proceduresToPerform;
      this.attendanceGroup = attendanceGroup;
      this.blog = blog;
      this.tutor = tutor;
    }
  }

  setAddress(address: AddressEntity): void {
    this.address = address;
  }
}
