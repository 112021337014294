import { BaseEntity } from './base.entity';
import { BaseInterface } from '../interfaces/base.interface';

export class StatusEntity extends BaseEntity implements BaseInterface{
  name: string;
  constructor(id?: number) {
    super(id);
  }
  setRawValue(entity): void {
    const {
      name,
    } = entity;
    this.name = name;
  }
}
