import { BaseInterface } from '../interfaces/base.interface';
import { BaseEntity } from './base.entity';
import { ProviderEntity } from './provider.entity';
import { PmeEntity } from './pme.entity';

export class UserEntity extends BaseEntity implements BaseInterface{
  name: string;
  createDate: string;
  email: string;
  profile: string;
  isActive: boolean;
  provider: ProviderEntity;
  pme: PmeEntity;

  constructor(id?: number) {
    super(id);
  }
  setRawValue(entity): void {
    if (entity) {
      const {
        name,
        createDate,
        email,
        profile,
        isActive,
        provider,
        pme,
      } = entity;
      this.name = name;
      this.createDate = createDate;
      this.email = email;
      this.profile = profile;
      this.isActive = isActive;
      this.pme = pme;
      if (provider?.id) {
        const p = new ProviderEntity(provider.id);
        p.setRawValue(provider);
        this.provider = p;
      } else {
        this.provider = null;
      }
    }
  }

  setProviderById(providerId: number): void {
    if (providerId) {
      this.provider = new ProviderEntity(providerId);
    } else {
      this.provider = null;
    }
  }

  isAdmin(): boolean {
    return this.profile === 'admin';
  }

  isProvider(): boolean {
    return this.profile === 'provider';
  }

  isPme(): boolean {
    return this.profile === 'pme';
  }
}
