export class ConvertToCSV {
    convert(objArray) : string {
        // const array = [Object.keys(objArray[0])].concat(objArray)

        // return array.map(it => {
        //   return Object.values(it).toString()
        // }).join('\n')
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(";")}` + '\r\n';
   
        return array.reduce((str, next) => {
            str += `${Object.values(next).map(value => `"${value}"`).join(";")}` + '\r\n';
            return str;
           }, str);
    }
}
