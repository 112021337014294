import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { ProviderScheduleEntity } from '../entities/provider-schedule.entity';
import {CheckOutEntity} from '../entities/checkout.entity';
import {Formatter} from '../utils/formatter';
import { ConvertToCSV } from '../utils/ConvertToCSV';

@Injectable()
// @ts-ignore
export class ProviderService extends ApiService<ProviderEntity> {
  constructor(http: HttpClient) {
    super('providers', http);
  }
  logsByProviderId(id: number): Promise<any[]> {
    return this.http
      .get(`${this.url}/${id}/active-logs${this.getParamsAsString()}`)
      .pipe(
        map(response => {
          return response as any[];
        }),
        catchError(this.handleError)
      ).toPromise();
  }
  findAllProviderSchedules = (providerId: number, startDate: string, endDate: string, procedures = null): Promise<Array<ProviderScheduleEntity>> => {
    let params = new HttpParams();
    params = params.append('isActive', 'true');
    params = params.append('isCancelled', '0');
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    if (procedures) {
      params = params.append('procedures', procedures);
    }

    return this.http.get(`${this.api}/providers/${providerId}/schedules`, { headers: this.header, params })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
  performanceByProvider(): Promise<any> {
    return this.http.get(`${this.url}/performance${this.getParamsAsString()}`, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
  findAllProceduresByClientByProvider(providerId: number): Promise<CheckOutEntity[]> {
    return this.http.get(`${this.url}/${providerId}/procedures/client${this.getParamsAsString()}`, { headers: this.header })
      .pipe(
        map(response => response as CheckOutEntity[]),
        catchError(this.handleError)
      ).toPromise();
  }
  performanceRating(): Promise<any> {
    return this.http.get(`${this.url}/performance/rating${this.getParamsAsString()}`, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
  findAllProviderExtractTotal = (providerId: number): Promise<Array<ProviderScheduleEntity>> => {
    return this.http.get(`${this.api}/providers/${providerId}/extract/total${this.getParamsAsString()}`, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
  findAllProviderExtractProceduresNoId(): Promise<Array<ProviderScheduleEntity>> {
    return this.http.get(`${this.api}/providers/extract/procedures`, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
  findAllProviderExtractProcedures = (providerId: number, startDate: string = null, endDate: string = null): Promise<Array<ProviderScheduleEntity>> => {
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', startDate);
    }
    if (endDate) {
      params = params.append('endDate', endDate);
    }
    return this.http.get(`${this.api}/providers/${providerId}/extract/procedures${this.getParamsAsString()}`, { headers: this.header, params })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
  deactiveProviderById(id: number, description: string): Promise<any> {
    return this.http.post(`${this.url}/${id}/deactive/`, {
      id: null,
      event: 'deactive',
      description,
      createDate: null,
    }, { headers: this.header })
      .pipe(
        map(response => response as any),
        catchError(this.handleError)
      ).toPromise();
  }
  activeProviderById(id: number, description: string): Promise<any> {
    return this.http.post(`${this.url}/${id}/active/`, {
      id: null,
      event: 'active',
      description,
      createDate: null,
    }, { headers: this.header })
      .pipe(
        map(response => response as any),
        catchError(this.handleError)
      ).toPromise();
  }
  handlingPerformanceChart(response: any): any {
    const cancellationByProvider = response.cancellationByProvider || 0;
    const cancellationsByClient = response.cancellationsByClient || 0;
    const accepted = response.accepted || 0;
    const confirmed = response.confirmed || 0;
    const performed = response.performed || 0;
    const countTotal = cancellationByProvider + cancellationsByClient + accepted + confirmed + performed;
    const getPercentage = (n: number): number  => parseFloat((n / countTotal).toFixed(2));
    const data = [
      cancellationByProvider,
      cancellationsByClient,
      accepted,
      confirmed,
      performed,
      // getPercentage(cancellationByProvider),
      // getPercentage(cancellationsByClient),
      // getPercentage(accepted),
      // getPercentage(confirmed),
      // getPercentage(performed),
    ];
    const dataset = [
      {
        data,
        label: 'Checkouts %',
        backgroundColor: [
          'rgba(34, 174, 187, 0.80)',
          'rgba(238, 66, 75, 0.80)',
          'rgba(251, 159, 61, 0.80)',
          'rgba(112,251,61,0.8)',
          'rgba(80,61,251,0.8)',
        ],
      },
    ];
    return {
      labels: [
        'Canc. Prestador',
        'Canc. Usuário',
        'Aceitos',
        'Confirmados',
        'Executados',
      ],
      data: dataset,
    };

  }
  exportCsvBasic(items: Array<any>): void {
    // let csv = 'Data; Procedimento; Cliente; Data Aquisição; Status; Forma de Pagamento; Valor R$\n';
    // console.log(items);
    // items.forEach((item) => {
    //   csv += item.createDate;
    //   csv += ';' + item.procedureLabel;
    //   csv += ';' + item.clientLabel;
    //   csv += ';' + item.paymentDate;
    //   csv += ';' + item.statusLabel;
    //   csv += ';' + item.paymentType;
    //   csv += ';' + item.total;
    //   csv += '\n';
    // });
    const con = new ConvertToCSV();
    let csv = con.convert(items);
    const hiddenElement = document.createElement('a') as any;
    hiddenElement.href = 'data:text/csv;charset=UTF-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'performance-prestador.csv';
    hiddenElement.click();
  }
}
