import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {KitService} from '../../../services/kit.service';
import {KitEntity} from '../../../entities/kit.entity';
import { SubscriptionEntity } from '../../../entities/subscription.entity';

@Component({
  selector: 'app-async-select-kit',
  templateUrl: './async-select-kit.component.html',
  styleUrls: ['./async-select-kit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectKitComponent),
      multi: true,
    },
    KitService
  ],
})
export class AsyncSelectKitComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() clientId = 0;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() bindValue = 'id';
  @Input() id?: string;
  @Input() showFullName = false;
  @Output() kitSelected = new EventEmitter<KitEntity>();
  @Output() kitsLoaded = new EventEmitter<Array<KitEntity>>();
  @Output() clear = new EventEmitter<boolean>();

  loading: boolean;
  kits: Array<KitEntity> = [];
  input: string;
  constructor(
    private kitService: KitService,
  ) {
  }

  private loadKits(page = 1): void {
    this.loading = true;
    this.kitService.setParam('page', page);
    if (this.clientId > 0) {
      this.kitService.setParam('clientId', this.clientId);
    }
    this.kitService.setParam('providerId', this.kitService.getUserLogged()?.provider?.id);
    this.kitService.findAll().then(
      (data: any) => {
        data.results.map((i) => { i.fullName = i.id + ' - ' + i.name; return i; })
        this.kits = [...this.kits, ...data.results];
        if (this.kits.length < data.count) {
          this.loadKits(page + 1);
          return;
        }

        this.kitsLoaded.emit(this.kits);
        this.loading = false;
      }, () => {
        this.kitsLoaded.emit(this.kits);
        this.loading = false;
      },
    );
  }
  ngOnInit(): void {
    this.loadKits();
  }
  onChange: any = () => { };
  onTouch: any = () => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }

  selected(item: KitEntity) {
    this.kitSelected.emit(item);
  }

  clearInput($event: any) {
    this.clear.emit(true);
  }
}
