import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {ContentEntity} from '../entities/content.entity';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class ExportDataService {
  // tslint:disable-next-line:variable-name
  _pdfMake: any;
  // tslint:disable-next-line:variable-name
  _content: ContentEntity;
  // tslint:disable-next-line:variable-name
  _pageOrientation: string;
  constructor() {
    this._pdfMake = pdfMake;
  }
  set content(content: ContentEntity) {
    this._content = content;
  }
  set pageOrientation(s: string) {
    this._pageOrientation = s;
  }
  private getConfig() {
    return {
      pageOrientation: !!this._pageOrientation ? this._pageOrientation : 'portrait',
      content: [ ...this._content.lines], styles: this._content.styles
    };
  }
  print() {
    if (!!this._pdfMake && !!this._content) {
      this._pdfMake.createPdf(this.getConfig()).print();
    }
  }
  download() {
    if (!!this._pdfMake && !!this._content) {
      this._pdfMake.createPdf(this.getConfig()).download();
    }
  }
  open() {
    if (!!this._pdfMake && !!this._content) {
      this._pdfMake.createPdf(this.getConfig()).open();
    }
  }
}
