import { NgModule } from '@angular/core';

import { registerLocaleData, CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/pt-PT';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CalendarModule} from 'angular-calendar';
import {ToastNotificationsModule} from 'ngx-toast-notifications';
import {TokenInterceptor} from 'src/app/shared/services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MaterialModule} from './material.module';
import {ConfirmScheduleEventModalComponent} from './shared/components/modals/confirm-schedule-event-modal/confirm-schedule-event-modal.component';
import {ConfirmScheduleEventModalModule} from './shared/components/modals/confirm-schedule-event-modal/confirm-schedule-event-modal.module';
import {CreateScheduleEventModalComponent} from './shared/components/modals/create-schedule-event-modal/create-schedule-event-modal.component';
import {CreateScheduleEventModalModule} from './shared/components/modals/create-schedule-event-modal/create-schedule-event-modal.module';
import {
  AuthGuard,
  UsersGuard,
  AdminGuard,
  ProceduresGuard,
  ClientsGuard,
  NotificationsGuard,
  CouponsGuard,
  KitsGuard, ProviderGuard, PmeGuard
} from './shared/guards';
import {UtilService} from './shared/services/util.service';
import {ConfirmScheduleNotificationDialogModule} from './shared/components/confirm-schedule-notification-dialog/confirm-schedule-notification-dialog.module';
import {ConfirmScheduleNotificationDialogComponent} from './shared/components/confirm-schedule-notification-dialog/confirm-schedule-notification-dialog.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RequestScheduleEventModalComponent } from './shared/components/modals/request-schedule-modal/request-schedule-event-modal.component';
import { RequestScheduleEventModalModule } from './shared/components/modals/request-schedule-modal/request-schedule-event-modal.module';
import {ShowRatingCommentModalComponent} from './shared/components/modals/show-rating-comment-modal/show-rating-comment-modal.component';
import {ShowRatingCommentModalModule} from './shared/components/modals/show-rating-comment-modal/show-rating-comment-modal.module';
import {ReasonModalComponent} from './shared/components/modals/reason-modal/reason-modal.component';
import {ReasonModalModule} from './shared/components/modals/reason-modal/reason-modal.module';
import {LogsProviderModalModule} from './shared/components/modals/logs-provider-modal/logs-provider-modal.module';
import {LogsProviderModalComponent} from './shared/components/modals/logs-provider-modal/logs-provider-modal.component';
import {LogsSubscriptionModalComponent} from './shared/components/modals/logs-subscription-modal/logs-subscription-modal.component';
import {LogsSubscriptionModalModule} from './shared/components/modals/logs-subscription-modal/logs-subscription-modal.module';
import {ConfirmYesNoModalModule} from './shared/components/modals/confirm-yes-no-modal/confirm-yes-no-modal.module';
import {ConfirmYesNoModalComponent} from './shared/components/modals/confirm-yes-no-modal/confirm-yes-no-modal.component';
import NotificationModalModule from './shared/components/modals/notification-modal/notification-modal.module';
import {NotificationModalComponent} from './shared/components/modals/notification-modal/notification-modal.component';
import {ItemsCheckoutModalComponent} from './shared/components/modals/items-checkout-modal/items-checkout-modal.component';
import {ItemsCheckoutModalModule} from './shared/components/modals/items-checkout-modal/items-checkout-modal.module';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomMatPaginatorIntl} from './shared/entities/mat-paginator-intl.entity';
import {
  TranslateModule,
} from '@ngx-translate/core';
import { ConfirmReasonModalModule } from './shared/components/modals/confirm-reason-modal/confirm-reason-modal.module';
import { ConfirmReasonModalComponent } from './shared/components/modals/confirm-reason-modal/confirm-reason-modal.component';
registerLocaleData(localeFr);
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    CalendarModule,
    ToastNotificationsModule,
    BrowserAnimationsModule,
    CreateScheduleEventModalModule,
    RequestScheduleEventModalModule,
    ConfirmScheduleEventModalModule,
    ConfirmScheduleNotificationDialogModule,
    ShowRatingCommentModalModule,
    ReasonModalModule,
    LogsProviderModalModule,
    LogsSubscriptionModalModule,
    ConfirmYesNoModalModule,
    ConfirmReasonModalModule,
    NotificationModalModule,
    ItemsCheckoutModalModule,
    TranslateModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    ProviderGuard,
    AdminGuard,
    UsersGuard,
    ProceduresGuard,
    ClientsGuard,
    NotificationsGuard,
    KitsGuard,
    CouponsGuard,
    PmeGuard,
    UtilService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  entryComponents: [
    CreateScheduleEventModalComponent,
    ConfirmScheduleEventModalComponent,
    RequestScheduleEventModalComponent,
    ConfirmScheduleNotificationDialogComponent,
    ShowRatingCommentModalComponent,
    ReasonModalComponent,
    LogsProviderModalComponent,
    LogsSubscriptionModalComponent,
    ConfirmYesNoModalComponent,
    ConfirmReasonModalComponent,
    NotificationModalComponent,
    ItemsCheckoutModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
