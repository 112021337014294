import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule} from '@angular/material/button';
import { RequiredFieldModule } from '../../required-field/required-field.module';
import { ConfirmReasonModalComponent } from './confirm-reason-modal.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    RequiredFieldModule,
  ],
  declarations: [
    ConfirmReasonModalComponent,
  ],
  exports: [
    ConfirmReasonModalComponent,
  ],
})
export class ConfirmReasonModalModule {}
