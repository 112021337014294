import {BaseEntity} from './base.entity';
import {BaseInterface} from '../interfaces/base.interface';
import {ProcedureEntity} from './procedure.entity';
import {ProviderEntity} from './provider.entity';
import {KitEntity} from './kit.entity';
import {ProviderScheduleEntity} from './provider-schedule.entity';

export class CheckoutItemEntity extends BaseEntity implements BaseInterface {
  procedure: ProcedureEntity;
  provider: ProviderEntity;
  kit: KitEntity;
  providerSchedule: ProviderScheduleEntity;
  createDate: string;
  scheduleDatetime: Date;
  constructor(id?: number) {
    super(id);
  }

  setRawValue(entity): void {
    const {
      procedure,
      provider,
      kit,
      providerSchedule,
      createDate,
      scheduleDatetime
    } = entity;
    this.procedure = procedure;
    this.provider = provider;
    this.kit = kit;
    this.providerSchedule = providerSchedule;
    this.createDate = createDate;
    this.scheduleDatetime = scheduleDatetime;
  }
}
