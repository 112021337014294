<div class="button-container" [formGroup]="formGroup">
  <ng-select
    [appendTo]="appendToBody ? 'body' : ''"
    [loading]="loading"
    [items]="kits"
    [bindValue]="bindValue"
    [bindLabel]="showFullName ? 'fullName' : 'name'"
    [multiple]="multiple"
    [formControlName]="formControlName"
    (change)="selected($event)"
    (clear)="clearInput($event)"
  ></ng-select>
</div>
