import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {StatusService} from '../../../services/status.service';
import {StatusEntity} from '../../../entities/status.entity';

@Component({
  selector: 'app-async-select-status',
  templateUrl: './async-select-status.component.html',
  styleUrls: ['./async-select-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectStatusComponent),
      multi: true,
    }, StatusService,
  ],
})
export class AsyncSelectStatusComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() statusLoaded = new EventEmitter<Array<StatusEntity>>();
  loading: boolean;
  status: Array<StatusEntity> = [];
  input: string;
  constructor(
    private statusService: StatusService,
  ) {
  }
  private loadStatus(page = 1): void {
    this.loading = true;
    this.statusService.setParam('page', page);
    this.statusService.findAll().then(
      (data: any) => {
        this.status = [...this.status, ...data.results];
        if (this.status.length < data.count) {
          this.loadStatus(page + 1);
        }
        this.status = this.status.map((status) => {
          const s = new StatusEntity(status.id);
          s.setRawValue(status);
          return s;
        });
        this.loading = false;
        this.statusLoaded.emit(this.status);
      }, () => {
        this.loading = false;
        this.statusLoaded.emit([] as Array<StatusEntity>);
      },
    );
  }
  ngOnInit(): void {
    this.loadStatus();
  }
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }
}
