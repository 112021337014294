
export class PaginatedListEntity<T> {
  numPages: number;
  count: number;
  itensPerPage: number;
  results: Array<T>;
  resultsAlreadyLoaded: Array<Array<T>>;
  pageIndex: number;
  constructor(data) {
    const {
      numPages,
      count,
      results,
      itensPerPage = 15,
    } = data;
    this.numPages = numPages;
    this.count = count;
    this.results = results;
    this.itensPerPage = itensPerPage;
    this.resultsAlreadyLoaded = [];
    this.pageIndex = 0;
  }
  setResults(results: T[]): void {
    this.results = results ? results : [];
  }
  update(previousPageNumber, pageIndex, data): void {
    this.resultsAlreadyLoaded[previousPageNumber] = [ ...this.results];
    this.count = data.count;
    this.results = data.results;
    this.numPages = data.numPages;
    this.resultsAlreadyLoaded[pageIndex] = [ ...this.results];
    this.pageIndex = pageIndex;
  }
  getResultsAlreadyLoadedByPage(pageNumber: number): Array<T> {
    return this.resultsAlreadyLoaded && this.resultsAlreadyLoaded[pageNumber];
  }
}
