import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { CheckOutEntity } from '../entities/checkout.entity';
import { catchError, map } from 'rxjs/operators';
@Injectable()
// @ts-ignore
export class CheckoutService extends ApiService<CheckOutEntity> {
  constructor(http: HttpClient) {
    super('checkouts', http);
  }

  cancelCheckout(id: string, description: string): Promise<any> {
    return this.http
      .post(`${this.url}/${id}/cancel/`, { "description": description })
      .pipe(
        map(response => {
          console.log(response);
          return response;
        }),
        catchError(this.handleError)
      ).toPromise();
  }
}
