<div class="yes-no-modal">
  <div class="row">
    <div class="col-md-12 text-center">
      <h4 class="title">Confirmação</h4>
      <div class="close"><span (click)="close()">&times;</span></div>
    </div>
  </div>
  <hr>
  <form [formGroup]="form" (submit)="onSubmit()" id="form-reason-id">
    <div class="row">
      <div class="col-md-12">
        <label for="reason">{{text}}:</label>
        <textarea
          name="reason"
          id="reason"
          cols="30"
          rows="5"
          class="form-control"
          formControlName="reason"
          style="resize: none"
        ></textarea>
        <app-required-field [field]="form.get('reason')" [field]="'Motivo'"></app-required-field>
      </div>
    </div>
  </form>
  <hr>
  <div class="confirm-schedule-modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button mat-flat-button type="button" (click)="close()" class="btn-cancel btn-block">Não</button>
      </div>
      <div class="col-md-6">
        <button
          mat-raised-button
          form="form-reason-id"
          type="submit"
          color="primary"
          class="btn-block"
          [disabled]="form.invalid"
        >Sim</button>
      </div>
    </div>
  </div>
</div>
