import { BaseEntity } from '.';
import { BaseInterface } from '../interfaces/base.interface';
import { ProviderEntity } from './provider.entity';
import { ClientEntity } from './client.entity';
import { ProcedureEntity } from './procedure.entity';
import { CheckOutEntity } from './checkout.entity';

export class ProviderScheduleEntity extends BaseEntity implements BaseInterface {
  provider: ProviderEntity;
  client: ClientEntity;
  procedures: Array<ProcedureEntity>;
  phone: string;
  type: string;
  isCancelled: boolean;
  isConfirmed: boolean;
  isPerformed: boolean;
  startDate: string;
  endDate: string;
  createDate: string;
  isActive: boolean;
  particularClientName: string;
  healthPlan: string;
  particularProcedure: string;
  isAccepted: boolean;
  isUserApp: boolean;
  pin: string;
  checkout: CheckOutEntity;
  status: string;
  constructor(id?: number) {
    super(id);
  }

  setRawValue(entity: any): void {
    if (entity) {
      const {
        provider,
        client,
        procedures,
        phone,
        type,
        isConfirmed,
        isPerformed,
        isCancelled,
        startDate,
        endDate,
        createDate,
        isActive,
        particularClientName,
        healthPlan,
        particularProcedure,
        isAccepted,
        isUserApp,
        pin,
        checkout,
        status } = entity;

      this.provider = provider;
      this.procedures = procedures;
      this.client = client;
      this.phone = phone;
      this.type = type;
      this.isConfirmed = isConfirmed;
      this.isPerformed = isPerformed;
      this.isCancelled = isCancelled;
      this.startDate = startDate;
      this.endDate = endDate;
      this.createDate = createDate;
      this.isActive = isActive;
      this.particularClientName = particularClientName;
      this.particularProcedure = particularProcedure;
      this.healthPlan = healthPlan;
      this.isAccepted = isAccepted;
      this.isUserApp = isUserApp;
      this.pin = pin;
      this.checkout = checkout;
      this.status = status;
    }
  }

  setCheckoutById(checkoutId: number): void{
    if (checkoutId) {
      this.checkout = new CheckOutEntity(checkoutId);
    } else {
      this.checkout = null;
    }
  }
  setProviderById(providerId: number): void {
    if (providerId) {
      this.provider = new ProviderEntity(providerId);
    } else {
      this.provider = null;
    }
  }
  setClientById(clientId: number): void {
    if (clientId) {
      this.client = new ClientEntity(clientId);
    } else {
      this.client = null;
    }
  }
}
