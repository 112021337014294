<div class="button-container" [formGroup]="formGroup">
  <ng-select
    [appendTo]="appendToBody ? 'body' : ''"
    [loading]="loading"
    [items]="teams"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [multiple]="multiple"
    [formControlName]="formControlName"
  ></ng-select>
</div>
