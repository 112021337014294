export class Formatter {
  public static cnpj(value: string): string {
    const nums = value && value.match(/\d/g) ? value.match(/\d/g).join('') : null;
    if (!nums) {
      return value;
    } else {
      if (nums.length === 14) {
        return `${nums.slice(0, 2)}.${nums.slice(2, 5)}.${nums.slice(5, 8)}/${nums.slice(8, 12)}-${nums.slice(12, 15)}`;
      } else {
        return nums && nums.length ? nums : '';
      }
    }
  }
  public static cpf(value: string): string {
    const nums = value && value.match(/\d/g) ? value.match(/\d/g).join('') : null;
    if (!nums) {
      return value;
    } else {
      if (nums.length === 11) {
        return `${nums.slice(0, 3)}.${nums.slice(3, 6)}.${nums.slice(6, 9)}-${nums.slice(9, 11)}`;
      } else {
        return nums && nums.length ? nums : '';
      }
    }
  }
  public static phoneOrCellPhone(value: string): string {
    const nums = value && value.match(/\d/g) ? value.match(/\d/g).join('') : null;
    if (!nums) {
      return value;
    } else {
      if (nums.length === 10) {
        return `(${nums.slice(0, 2)}) ${nums.slice(2, 6)} - ${nums.slice(6, 10)}`;
      } else if (nums.length === 11) {
        return `(${nums.slice(0, 2)}) ${nums.slice(2, 7)} - ${nums.slice(6, 11)}`;
      } else {
        return value;
      }
    }
  }
  public static formatMoney(amount, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - parseInt(i, 10)).toFixed(decimalCount).slice(2) : '');
    } catch (e) {
      console.log(e);
    }
  }
  public static stringToHTML(str): HTMLDivElement {
    const aux = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const div = document.createElement('div');
    div.className = 'content';
    const { children } = doc.body;
    // HTML Collection não é do tipo Array logo, sua ordem não tem uma sequencia e não tem controle sobre os items
    for (let i = 0; i < children.length; i++) {
      aux.push(children.item(i));
    }
    aux.forEach(it => {
      div.appendChild(it);
    });
    return div;
  }
}
