import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmScheduleNotificationDialogComponent} from './confirm-schedule-notification-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatButtonModule,
  ],
  declarations: [
    ConfirmScheduleNotificationDialogComponent,
  ],
  exports: [
    ConfirmScheduleNotificationDialogComponent,
  ]
})
export class ConfirmScheduleNotificationDialogModule {}
