import { BaseEntity } from './base.entity';
import { BaseInterface } from '../interfaces/base.interface';
import { KitEntity } from './kit.entity';
import { ClientEntity } from './client.entity';
import { CheckoutItemEntity } from './checkout-item.entity';

export class CheckOutEntity extends BaseEntity implements BaseInterface {
  pin: string;
  code: string;
  client: ClientEntity;
  clientBuy: ClientEntity;
  subscriptionFee: boolean;
  scheduled: boolean;
  performed: boolean;
  total: number;
  totalItems: number;
  totalSubscription: number;
  kits: Array<KitEntity>;
  paymentDate: string;
  paymentType: any;
  createDate: string;
  items: CheckoutItemEntity[];
  nfseNumberSplit: string;
  nfseUrl: string;
  scheduleDatetime: Date;

  constructor(id?: number) {
    super(id);
    this.items = [];
  }

  setRawValue(entity): void {
    const {
      code,
      pin,
      client,
      subscriptionFee,
      scheduled,
      performed,
      kits,
      total,
      totalItems,
      totalSubscription,
      paymentDate,
      paymentType,
      createDate,
      items,
      nfseNumberSplit,
      nfseUrl,
      scheduleDatetime,
    } = entity;

    this.code = code;
    this.pin = pin;
    this.client = client;
    this.scheduled = scheduled;
    this.performed = performed;
    this.subscriptionFee = subscriptionFee;
    this.kits = kits;
    this.total = total;
    this.totalItems = totalItems;
    this.totalSubscription = totalSubscription;
    this.paymentDate = paymentDate;
    this.paymentType = paymentType;
    this.createDate = createDate;
    this.nfseNumberSplit = nfseNumberSplit;
    this.nfseUrl = nfseUrl;
    this.scheduleDatetime = scheduleDatetime;
    if (items?.length) {
      this.items = items.map((item) => {
        const i = new CheckoutItemEntity(item.id);
        i.setRawValue(item);
        return i;
      });
    }
  }
}
