<div class="logs">
  <div class="header-modal">
    <div class="close" (click)="close()">&times;</div>
  </div>
  <div class="body-modal">

  </div>
  <div class="container-table">
    <mat-table [dataSource]="dataSource">
      <ng-container
        matColumnDef="{{ column.id }}"
        *ngFor="let column of columnNames">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ column.value }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element[column.id] }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns">
      </mat-row>
    </mat-table>
    <mat-paginator
      [pageSize]="3"
      [length]="dataSource?.data?.length"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
