import { BaseEntity } from './base.entity';
import { BaseInterface } from '../interfaces/base.interface';

export class ProviderTypeEntity extends BaseEntity implements BaseInterface{
  name: string;
  isActive: boolean;
  constructor(id?: number) {
    super(id);
  }
  setRawValue(entity): void {
    const {
      name,
      isActive
    } = entity;
    this.name = name;
    this.isActive = isActive;
  }
}
