import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncSelectCompanyComponent } from './async-select-company/async-select-company.component';
import { AsyncSelectTeamComponent } from './async-select-team/async-select-team.component';
import { AsyncSelectUserComponent } from './async-select-user/async-select-user.component';
import { AsyncSelectTypeProviderComponent } from './async-select-type-provider/async-select-type-provider.component';
import { AsyncSelectProcedureComponent } from './async-select-procedure/async-select-procedure.component';
import { AsyncSelectQuestionComponent } from './async-select-question/async-select-question.component';
import { AsyncSelectStatusComponent } from './async-select-status/async-select-status.component';
import { AsyncSelectClientComponent } from './async-select-client/async-select-client.component';
import { AsyncSelectProviderComponent } from './async-select-provider/async-select-provider.component';
import { AsyncSelectKitComponent } from './async-select-kit/async-select-kit.component';
import { AsyncSelectKitCheckoutComponent } from './async-select-kit-checkout/async-select-kit-checkout.component';
import { AsyncSelectProviderSubscriptionComponent } from './async-select-provider-subscription/async-select-provider-subscription.component';
import { AsyncSelectSubscriptionComponent } from './async-select-subscription/async-select-subscription.component';
import { AsyncSelectPmeComponent } from './async-select-pme/async-select-pme.component';
import { AsyncSelectAttendanceGroupComponent } from './async-select-attendance-group/async-select-attendance-group.component';
import { AsyncBlogComponent } from './async-select-blog/async-select-blog.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AsyncSelectUserComponent,
    AsyncSelectCompanyComponent,
    AsyncSelectTeamComponent,
    AsyncSelectTypeProviderComponent,
    AsyncSelectProcedureComponent,
    AsyncSelectQuestionComponent,
    AsyncSelectStatusComponent,
    AsyncSelectClientComponent,
    AsyncSelectProviderComponent,
    AsyncSelectKitComponent,
    AsyncSelectKitCheckoutComponent,
    AsyncSelectSubscriptionComponent,
    AsyncSelectProviderSubscriptionComponent,
    AsyncSelectSubscriptionComponent,
    AsyncSelectPmeComponent,
    AsyncSelectAttendanceGroupComponent,
    AsyncBlogComponent
  ],
  exports: [
    AsyncSelectUserComponent,
    AsyncSelectCompanyComponent,
    AsyncSelectTeamComponent,
    AsyncSelectTypeProviderComponent,
    AsyncSelectProcedureComponent,
    AsyncSelectQuestionComponent,
    AsyncSelectClientComponent,
    AsyncSelectStatusComponent,
    AsyncSelectProviderComponent,
    AsyncSelectKitComponent,
    AsyncSelectKitCheckoutComponent,
    AsyncSelectSubscriptionComponent,
    AsyncSelectProviderSubscriptionComponent,
    AsyncSelectSubscriptionComponent,
    AsyncSelectPmeComponent,
    AsyncSelectAttendanceGroupComponent,
    AsyncBlogComponent
  ],
})
export class AsyncSelectModule {
}
