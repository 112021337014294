<div class="module-register font-trovi">
  <div class="container mt-4">
    <div class="row">
      <div class="col-12 title">
        <button
          mat-icon-button
          (click)="close()"
          matTooltip="Voltar"
          class="btn-back"
        >
          <mat-icon class="orange-matine">keyboard_backspace</mat-icon>
        </button>
        <h3>Criação de Agendamento</h3>
      </div>
    </div>
    <hr />
    <form id="form-add-event" [formGroup]="formEvent" (submit)="onSubmit()">
      <div class="form-row">
        <div class="col-9">
          <div class="form-row">
            <div class="col-md-6">
              <label for="type">Tipo Cliente:</label>
              <div class="w-100" [formGroup]="formEvent">
                <ng-select
                  id="type"
                  [items]="clientTypes"
                  [bindLabel]="'label'"
                  [bindValue]="'id'"
                  (change)="onChangeClientType()"
                  [formControlName]="'type'"
                >
                </ng-select>
              </div>
            </div>
            <div class="col-md-6" *ngIf="clientTypeSelected !== 'app'">
              <label for="phone">Celular:</label>
              <input
                type="text"
                [dropSpecialCharacters]="false"
                [formControlName]="'phone'"
                mask="(00) 00000-0000"
                [formGroup]="formEvent"
                id="phone"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-row" *ngIf="clientTypeSelected === 'app'">
            <div class="col-12">
              <label for="client">Selecione o cliente:</label>
              <app-async-select-client
                [formGroup]="formEvent"
                [formControlName]="'client'"
                (clientsLoaded)="onClientsLoaded($event)"
                id="client"
                (click)="onChangeClient()"
                (keyup.enter)="onChangeClient()"
              >
              </app-async-select-client>
            </div>
          </div>
        </div>

        <div *ngIf="clientTypeSelected === 'app'" class="col-3 top-0">
          <div class="card">
            <div class="card-body p-0">
              <div class="text-center">
                <img
                  src="{{ clientImg }}"
                  class="img-client"
                  width="165"
                  height="167"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="clientTypeSelected !== 'app'">
        <div class="form-row">
          <div class="col">
            <label for="client">Cliente:</label>
            <input
              [formControlName]="'particularClientName'"
              [formGroup]="formEvent"
              id="client"
              class="form-control"
            />
          </div>
          <div class="col">
            <label for="healthPlan">Convênio:</label>
            <input
              [formControlName]="'healthPlan'"
              [formGroup]="formEvent"
              id="healthPlan"
              class="form-control"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="col-12">
            <label for="procedure">Procedimento:</label>
            <input
              [formControlName]="'particularProcedure'"
              [formGroup]="formEvent"
              id="procedure"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div *ngIf="clientTypeSelected === 'app'">

        <div class="form-row" *ngIf="formEvent?.get('client')?.value">
          <div class="col-12">
            <label for="kit">Selecione o Compra:</label>
            <app-async-select-kit-checkout
            id="kit"
            [formGroup]="formEvent"
            [clientId]="formEvent?.get('client')?.value"
            [formControlName]="'kit'"
            (kitsLoaded)="kits = $event"
            ></app-async-select-kit-checkout>
          </div>
        </div>
        <div class="form-row" *ngIf="formEvent?.get('client')?.value">
          <div class="col-12">
            <label for="procedures">Procedimento:</label>
            <ng-select
              id="procedures"
              [items]="procedures"
              [bindValue]="'id'"
              [bindLabel]="'name'"
              [multiple]="true"
              [formControlName]="'procedures'"
            ></ng-select>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6">
          <label for="date">Data:</label>
          <input
            id="date"
            [formGroup]="formEvent"
            [formControlName]="'date'"
            type="date"
            class="form-control"
          />
        </div>
        <div class="col-md-3">
          <label for="startHour">Início:</label>
          <input
            id="startHour"
            [formGroup]="formEvent"
            [formControlName]="'startHour'"
            type="time"
            class="form-control"
          />
        </div>
        <div class="col-md-3" *ngIf="clientTypeSelected !== 'app'">
          <label for="endHour">Até:</label>
          <input
            id="endHour"
            [formGroup]="formEvent"
            [formControlName]="'endHour'"
            type="time"
            class="form-control"
          />
        </div>
      </div>

    </form>
    <div class="footer">
      <div *ngIf="!isOpeningHour()" class="row position-absolute">
        <div class="blue-trovi h5 col">
          <strong>Atenção: </strong>O horário da consulta esta fora do horário
          de funcionamento vinculado ao prestador.
        </div>
      </div>
      <hr class="hr-relative" />
      <div class="row">
        <div class="col text-right">
          <button
            form="form-add-event"
            color="primary"
            class="btn btn-block"
            [disabled]="isInvalidForm()"
            type="submit"
            mat-raised-button
            class="btn-confirm"
          >
            <i class="fa fa-calendar-plus-o fa-lg" aria-hidden="true"></i> Criar
            Agendamento
          </button>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
  ></ngx-loading>
</div>
