import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Subject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
// @ts-ignore
export class ProvidersScheduleService extends ApiService<ProviderScheduleEntity> {
  constructor(http: HttpClient) {
    super('provider-schedules', http);
  }

  private subject = new Subject<any>();

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  cancellation(providerScheduleId): Promise<any> {
    return this.http.post(`${this.url}/${providerScheduleId}/cancellation/`, { responsible: 'provider', description: 'cancelado pelo prestador'}, { headers: this.header })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      ).toPromise();
  }
}
