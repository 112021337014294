import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UserService} from '../../../services';
import {QuestionService} from '../../../services/question.service';
import {QuestionEntity} from '../../../entities/question.entity';

@Component({
  selector: 'app-async-select-question',
  templateUrl: './async-select-question.component.html',
  styleUrls: ['./async-select-question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectQuestionComponent),
      multi: true,
    }, QuestionService,
  ],
})
export class AsyncSelectQuestionComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() appendToBody = false;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() id?: string;
  @Output() questionsLoaded = new EventEmitter<Array<QuestionEntity>>();
  loading: boolean;
  questions: Array<QuestionEntity> = [];
  input: string;
  constructor(
    private questionService: QuestionService,
  ) {
  }
  private loadQuestions(page = 1): void {
    this.loading = true;
    this.questionService.setParam('page', page);
    this.questionService.findAll().then(
      (data: any) => {
        this.questions = data.map((question) => {
          const q = new QuestionEntity(question.id);
          q.setRawValue(question);
          return q;
        });
        this.loading = false;
        this.questionsLoaded.emit(this.questions);
      }, () => {
        this.loading = false;
        this.questionsLoaded.emit([] as Array<QuestionEntity>);
      },
    );
  }
  ngOnInit(): void {
    this.loadQuestions();
  }
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string): void {
    this.input = input;
  }
}
