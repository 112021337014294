import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Toaster} from 'ngx-toast-notifications';
import {CryptService} from './crypt.service';

const mappedErrors = {
  20: 'Não é possível cancelar o agendamento com menos de 24 horas.',
  25: 'A compra não pode ser deletada pois já existe relacionamento com a mesma.',
  28: 'Não é possível deletar procedimento com vinculos',
};

@Injectable()
// @ts-ignore
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toaster: Toaster,
    private cryptService: CryptService,
  ) {
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const t = sessionStorage.getItem('token');
    const dupReq = req.clone({
      headers: req.headers.set('Authorization', `Token ${this.cryptService.decrypt(t)}`),
    });
    if (req.url.toLowerCase().includes('api.postmon.com.br') ||
        req.url.toLowerCase().includes('auth/epharma') ||
        req.url.toLowerCase().includes('restqa.epharma.com.br') ||
        req.url.toLowerCase().includes('rest.epharma.com.br') ||
        req.url.toLowerCase().includes('services.epharma.com.br') ||
        req.url.toLowerCase().includes('servicesqa.epharma.com.br')) {
      return next.handle(req);
    }
    return next.handle(dupReq).pipe( tap(() => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.toaster.open({
            text: mappedErrors[err.error?.errorCode] ? mappedErrors[err.error?.errorCode] : 'Erro desconhecido.',
            caption: `Oops!`,
            type: 'danger',
            position: 'top-right'
          });
        }
      }));
  }
}
