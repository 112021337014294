import {LineEntity} from './line.entity';
import {HeaderEntity} from './header.entity';
const s = {
  p: {
    fontSize: 18,
    bold: true,
    margin: [0, 0, 0, 15],
    color: '#777777',
  },
  title: {
    fontSize: 18,
    bold: true,
    margin: [0, 0, 0, 5],
    color: '#777777',
  },
  subtitle: {
    fontSize: 11,
    margin: [0, 0, 0, 15],
    color: '#777777',
  },
  filters: {
    fontSize: 9,
    margin: [0, 0, 0, 3],
    color: '#777777',
  },
  table: {
    margin: [0, 15, 0, 0],
  },
  tableHeader: {
    fontSize: 11,
    color: '#777777',
  },
  tableRow: {
    fontSize: 8,
    color: '#232323',
  }
};
export class ContentEntity {
  // tslint:disable-next-line:variable-name
  _lines: LineEntity[];
  // tslint:disable-next-line:variable-name
  _styles: any;
  constructor() {
    this._lines = [];
    this._styles = s;
  }
  get lines() {
    return this._lines;
  }
  get styles() {
    return this._styles;
  }
  public insertLineEmpty() {
    this._lines.push(new LineEntity('\n', ''));
  }
  public insertParagraph(text: string, className: string = 'p') {
    if (!!text) {
      this._lines.push(new LineEntity(text, className));
    }
  }
  public insertTable(header: HeaderEntity[], data: any[]) {
    const line = new LineEntity();
    line.buildTable(header, data);
    this._lines.push(line);
  }
}
