<div class="show-rating-comments">
  <div class="header-modal">
    <div class="close" (click)="close()">&times;</div>
  </div>
  <div class="body-modal">
    <div class="row">
      <div class="col-md-12 text-center">
        <h3>AVALIAÇÕES</h3>
        <div class="rating-wrapper">
          <star-rating
            style="margin-left: -4px"
            matTooltip="Filtro para avaliações"
            [starType]="'svg'"
            (ratingChange)="search($event)"
            [showHalfStars]="true"
          ></star-rating>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col-md-12 text-center m-auto">
      <mat-progress-spinner diameter="50"
                            style="margin: auto"
                            mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>
  <div class="container-table" *ngIf="!loading">
    <mat-table [dataSource]="dataSource">
      <ng-container
        matColumnDef="{{ column.id }}"
        *ngFor="let column of columnNames">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ column.value }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element[column.id] }} </mat-cell>
      </ng-container>
      <ng-container
        matColumnDef="rating">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Avaliação
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="rating-wrapper-column">
            <star-rating
              style="margin-left: -4px"
              [starType]="'svg'"
              [rating]="element.rating ? element.rating : 0"
              [readOnly]="true"
            ></star-rating>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns">
      </mat-row>
    </mat-table>
    <mat-paginator
      *ngIf="!!performanceRatingPaginatedList"
      [pageSize]="performanceRatingPaginatedList.itensPerPage"
      [pageIndex]="performanceRatingPaginatedList.pageIndex"
      [length]="performanceRatingPaginatedList.count"
      showFirstLastButtons
      (page)="changePage($event)"
    >
    </mat-paginator>
  </div>
<!--  <div class="footer-modal mt-1">-->
<!--    <div class="row" style="margin-right: 0">-->
<!--      <div class="col-md-12 text-right">-->
<!--        <div class="button-container">-->
<!--          <button mat-raised-button type="button" (click)="close()">FECHAR</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
