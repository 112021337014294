import { Component, OnInit, Inject } from '@angular/core';
import { ProcedureEntity } from 'src/app/shared/entities/procedure.entity';
import * as moment from 'moment';
import { get } from 'lodash';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ProviderScheduleEntity } from 'src/app/shared/entities/provider-schedule.entity';
import { ProvidersScheduleService } from 'src/app/shared/services/providers-schedule.service';
import { ClientEntity } from 'src/app/shared/entities/client.entity';
import { Toaster } from 'ngx-toast-notifications';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-schedule-event-modal',
  templateUrl: './request-schedule-event-modal.component.html',
  styleUrls: ['./request-schedule-event-modal.component.scss'],
  providers: [
    ProvidersScheduleService
  ],
})
export class RequestScheduleEventModalComponent implements OnInit {

  loading: boolean;
  procedures: Array<ProcedureEntity> = [];
  formEvent: FormGroup;
  clientTypes = [];
  clients: Array<ClientEntity>;
  imgDefault = '../../../../assets/images/default-user.png';
  clientImg = this.imgDefault;
  clientSelected: ClientEntity;
  clientTypeSelected: any;
  providerSchedule: ProviderScheduleEntity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RequestScheduleEventModalComponent>,
    private toaster: Toaster,
    private fb: FormBuilder,
    private providerScheduleService: ProvidersScheduleService
  ) {
  }

  ngOnInit(): void {
    this.clientTypes = [{
      id: 'app',
      label: 'Cliente App',
    }, {
      id: 'local',
      label: 'Cliente Externo (Particular)',
    }];
    this.buildForm();
    this.getProviderScheduleById();
  }

  private buildForm(): void {
    const formControl = (value = null, disabled = false, validators = [Validators.required]) => {
      return new FormControl({value, disabled}, validators);
    };
    this.formEvent = this.fb.group({
      date: formControl(null, true),
      startHour: formControl(null, true),
      endHour: formControl(null, true),
      client: formControl(null, true),
      procedures: formControl([], true),
      phone: formControl(null, true),
      type: formControl(null, true),
      isConfirmed: formControl(false, true),
      pin: formControl(null, true),
      particularProcedure: formControl(null, true),
      particularClientName: formControl(null, true),
      healthPlan: formControl(null, true),
    });
  }

  getProviderScheduleById() {
    this.loading = true;
    this.providerScheduleService
      .findById(this.data.providerScheduleId.toString())
      .then(
        (providerSchedule) => {
          this.providerSchedule = new ProviderScheduleEntity(providerSchedule.id);
          this.providerSchedule.setRawValue(providerSchedule)
          this.updateForm();
          this.loading = false;
        }, () => {
          this.loading = false;
        },
      );
  }

  updateForm(): void {
    const getValue = (key) => get(this.providerSchedule, key);

    this.clientTypeSelected = this.clientTypes.find(f => f.id === this.providerSchedule?.type);

    this.formEvent.get('date').setValue(moment(getValue('startDate')).format('YYYY-MM-DD'));
    this.formEvent.get('startHour').setValue(moment(getValue('startDate')).format('HH:mm:ss'));
    this.formEvent.get('endHour').setValue(moment(getValue('endDate')).format('HH:mm:ss'));
    this.formEvent.get('isConfirmed').setValue(getValue('isConfirmed'));
    this.formEvent.get('pin').setValue(getValue('pin'));
    this.formEvent.get('type').setValue(getValue('type'));

    if (this.providerSchedule?.type === 'app') {
      this.clientSelected = this.providerSchedule.client;
      if (this.clientSelected?.image && this.clientSelected?.image !== '') {
        this.clientImg = this.clientSelected.image;
      }
      this.formEvent.get('client').setValue(this.clientSelected?.id);
      this.formEvent.get('procedures').setValue(this.providerSchedule.procedures.map(p => p.id));
      this.formEvent.get('phone').setValue(getValue('client.phone'));
    } else if (this.providerSchedule?.type === 'local') {
      this.formEvent.get('particularProcedure').setValue(getValue('particularProcedure'));
      this.formEvent.get('particularClientName').setValue(getValue('particularClientName'));
      this.formEvent.get('healthPlan').setValue(getValue('healthPlan'));
    }

  }

  onClientsLoaded = (clients: Array<ClientEntity>) => {
    this.clients = clients;
    this.onChangeClient();
  }

  onChangeClient(): void {
    const clientId = this.formEvent.get('client')?.value;

    if (!clientId) {
      this.clientImg = this.imgDefault;
      this.clientSelected = null;
    }
    else if (clientId !== this.clientSelected?.id) {
      if (this.clients) {
        this.clientSelected = this.clients.find(client => client.id === clientId);
        this.formEvent.get('phone').setValue(this.clientSelected?.phone);
        this.clientImg = this.clientSelected.image && this.clientSelected.image !== '' ?
          this.clientSelected.image : this.imgDefault;
      }
    }
  }

  onChangeClientType(): void {
    this.clientImg = this.imgDefault;
    this.clientSelected = null;
    this.clientTypeSelected = this.formEvent.get('type').value;
    this.formEvent.get('phone').setValue('');

    this.formEvent.get('particularClientName').setValue(null);
    this.formEvent.get('healthPlan').setValue(null);
    this.formEvent.get('particularProcedure').setValue(null);
    this.formEvent.get('client').setValue(null);
    this.formEvent.get('procedures').setValue([]);
  }

  close(): void {
    this.dialogRef.close(false);
  }
  refuse(): void {
    this.onSubmit(false);
  }
  onSubmit(isAccepted = true): void {
    this.loading = true;
    this.providerScheduleService
      .save({...this.providerSchedule, isAccepted, isActive: isAccepted, isCancelled: !isAccepted})
      .then(
        () => {
          this.showSuccess(`${isAccepted ? 'Agendamento aceito.' : 'Agendamento recusado.'}`);
          this.dialogRef.close(true);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  showSuccess(text): void {
    this.toaster.open({
      text,
      caption: 'OK!',
      type: 'success',
      position: 'top-right'
    });
  }
}
